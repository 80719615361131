const INIT_STATE = {
  Category: [],
  addingCategory: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "deleteCategory_SUCCESS": {
      const categoryArray = removeDeletedCategoryFromState(
        state.Category,
        action.payload.Record
      );
      return {
        ...state,
        Category: [...categoryArray],
        addingCategory: false,
        success: true,
        textMessage: "category deleted successfully",
      };
    }

    case "editCategory_SUCCESS": {
      const categoryArr = getCategoryArray(
        state.Category,
        action.payload.Record
      );
      return {
        ...state,
        Category: [...categoryArr],
        addingCategory: false,
        success: true,
        textMessage: "category updated successfully",
      };
    }

    case "categoryList_SUCCESS": {
      return { ...state, Category: action.payload.Record };
    }

    case "addingCategory_START": {
      return { ...state, addingCategory: true, initialValues: null };
    }
    case "addCategory_SUCCESS": {
      return {
        ...state,
        Category: [action.payload.Record, ...state.Category],
        addingCategory: false,
        initialValues: null,
        success: true,
        textMessage: "category added successfully",
      };
    }
    case "addCategory_FAILURE": {
      return {
        ...state,
        addingCategory: false,
        success: false,
        error: true,
        textMessage: "some error occur while adding category. kindly try again",
      };
    }
    case "AddCategory_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }
    case "ResetPassword_Modal": {
      return {
        ...state,
        ShowModalPassword: !state.ShowModalPassword,
        initialValues: action.payload,
      };
    }

    case "resetCategoryState": {
      return {
        ...state,
        addingCategory: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
        ShowModalPassword: false,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getCategoryArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedCategoryFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
