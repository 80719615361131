const INIT_STATE = {
  drawerVisibility: false,
  dashboardData: null,
  topProducts: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getDashboardOverView_SUCCESS": {
      console.log(
        "==========getDashboardOverView=============",
        action.payload.Record
      );
      return { ...state, dashboardData: action.payload.Record };
    }
    case "getTopPerFormingProducts_SUCCESS": {
      action.payload.Record.map((item) => {
        if (!item.totalSale) {
          item.totalSale = 0;
        }
        return item;
      });
      return { ...state, topProducts: action.payload.Record };
    }
    case "getDashboardOverView_FAILURE": {
      return {
        ...state,
        textMessage: "some error has occurred while fetching dashboard data",
      };
    }
    default:
      return state;
  }
};
