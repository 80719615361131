import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { toggleCollapsedSideNav } from "redux/actions/Setting";
import UserInfo from "components/UserInfo";



import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {

  const { width, navCollapsed, navStyle } = useSelector(({ settings }) => settings);
  const authProps = useSelector((state) => state.auth);
  const {authUser} = authProps
  //const { searchText, setSearchText } = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const RedirectToMapPage = () => {
    history.push('/siteMap')
  }
  return (
    <Header>
      <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-w-100">
        <div className="gx-d-flex gx-align-items-center">
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-2">
              <i className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div> : null}
          <Link to="/" className="gx-d-none"> Swppp </Link>
        </div>

        <div className="gx-header-notifications gx-d-flex gx-align-items-center  gx-p-2 gx-p-sm-0">

          <div className="gx-d-flex gx-flex-column gx-flex-md-row gx-mr-1 gx-mr-sm-3">
         { authUser.role ===  'customer' ? '' : '' }
            <div className="iconsDiv gx-d-flex">
              { authUser.role ===  'customer' ?  <div className="gx-mr-1"><span className="iconMenuContainer" style={{ cursor: 'pointer' }} onClick={() => RedirectToMapPage()}><i className="icon icon-map-google" style={{ color: '#545454', fontSize: 16 }}></i></span></div> : ''}
              <div className="gx-mr-1 gx-d-none gx-d-sm-block"><span className="iconMenuContainer" style={{ cursor: 'pointer' }}><i className="icon icon-map-traffic-layer" style={{ color: '#545454', fontSize: 16 }}></i></span></div>
              <div className="gx-mr-1"><span className="iconMenuContainer" style={{ cursor: 'pointer' }}><i className="icon icon-notification" style={{ color: '#545454', fontSize: 16 }}></i></span></div>
            </div>
          </div>


          <div className="gx-user-nav"><UserInfo /> </div>
        </div>
      </div>

    </Header>
  );
};

export default Topbar;
