import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Route, Switch } from "react-router-dom";
import App from "./containers/App/index";
import store, { history } from './redux/store';
import "assets/vendor/style";
import "styles/index.less";
import "./assets/fonts/index.less"


const MainApp = () => 
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>;

export default MainApp;