const MenuList = {
  customer: [
    {
      title: "Order",
      url: "/customerOrder/all",
      icon: "orders",
      permissionKey: "customerOrder",
      top: "4px",
    },
  ],
  vendor: [
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: "dasbhoard",
      permissionKey: "dashboard",
      top: "4px",
    },
    {
      title: "Product",
      url: "/product/approve",
      icon: "feedback",
      keyTitle: "Product",
      permissionKey: "teamManagement",
      subMenu: [
        {
          title: "Approved Product",
          url: "/product/approved",
          icon: "",
          key: "Product",
        },
        {
          title: "Pending Product",
          url: "/product/pending",
          icon: "",
          key: "Product",
        },
      ],
    },
    {
      title: "Order",
      url: "/order/pending",
      icon: "auth-screen",

      badgeType: "vendor_order_badge",
      keyTitle: "Order",
      permissionKey: "Order",
      subMenu: [
        {
          title: "New Order",
          url: "/order/pending",
          icon: "",
          key: "Order",
        },
        {
          title: "In Progress",
          url: "/order/preparing",
          icon: "",
          key: "Order",
        },
        {
          title: "Delivered",
          url: "/order/delivered",
          icon: "",
          key: "Order",
        },
      ],
    },
    {
      title: "Offers",
      url: "/offers",
      icon: "inbuilt-apps",
      permissionKey: "dashboard",
      top: "4px",
    },
    {
      title: "Report",
      url: "/report",
      icon: "auth-screen",
      permissionKey: "dashboard",
      top: "4px",
    },
    {
      title: "Setting",
      url: "/setting",
      icon: "setting",
      permissionKey: "dashboard",
      top: "4px",
    },
  ],
  superAdmin: [
    {
      title: "Dashboard",
      url: "/admin/dashboard",
      icon: "dasbhoard",
      top: "4px",
      permissionKey: "dashboard",
    },
    {
      title: "Orders",
      url: "/admin/order",
      icon: "orders",
      top: "4px",
      permissionKey: "dashboard",
    },
    {
      title: "Users",
      url: "/admin/users",
      icon: "user",
      top: "4px",
      permissionKey: "dashboard",
    },
    {
      title: "Category",
      url: "/category",
      icon: "feedback",
      top: "4px",
      permissionKey: "swpppDocument",
    },
    {
      title: "Sub Category",
      url: "/subCategory",
      icon: "feedback",
      top: "4px",
      permissionKey: "inspector",
    },
    {
      title: "Vendor",
      url: "/vendor",
      icon: "wall",

      badgeType: "vendor_badge",
      keyTitle: "Vendor",
      permissionKey: "Vendor",
      subMenu: [
        {
          title: "All",
          url: "/vendor",
          icon: "",
          key: "Vendor",
        },
        {
          title: "Pending",
          url: "/pendingVendor",
          icon: "",
          key: "Vendor",
        },
      ],
    },
    {
      title: "Product",
      url: "/product/approve",
      icon: "auth-screen",

      badgeType: "product_badge",
      keyTitle: "Product",
      permissionKey: "teamManagement",
      subMenu: [
        {
          title: "All",
          url: "/product/all",
          icon: "",
          key: "Product",
        },
        {
          title: "Pending",
          url: "/product/pending",
          icon: "",
          key: "Product",
        },
      ],
    },
    {
      title: "Report",
      url: "/report",
      icon: "product-list",
      keyTitle: "Report",
      permissionKey: "Report",
      subMenu: [
        {
          title: "Store",
          url: "/report",
          icon: "",
          key: "Report",
        },
        {
          title: "Product",
          url: "/productReport",
          icon: "",
          key: "Report",
        },
        {
          title: "Static Users",
          url: "/staticUsers",
          icon: "",
          key: "Static Users",
        }
      ],
    },

    {
      title: "Settings",
      url: "/setting",
      icon: "setting",
      top: "4px",
      permissionKey: "settings",
    },
  ],
};

export default MenuList;
