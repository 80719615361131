import React from "react";
import Widget from "components/Widget";
import RevenuePieChart from "./RevenuePieChart";
const TopProducts = ({ topProducts }) => {
  var colorArray = ["#C39FDF", "#3FD4A2", "#0A7DD9", "#B9E886"];
  return (
    <Widget
      styleName={"revenueOverview"}
      title={
        <h4 className="gx-blue-font gx-font-sans-bold gx-mb-4 gx-text-capitalize">
          Top Performing Products
        </h4>
      }
    >
      <div className="gx-d-flex gx-justify-content-between">
        <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-p-5 gx-w-100">
          <div className="gx-mr-5 gx-w-75">
            {topProducts &&
              topProducts.map((item, index) => {
                return (
                  <div className="gx-d-flex  gx-mb-3 gx-mt-3">
                    <span
                      className="gx-font-gilory-medium gx-mr-2"
                      style={{
                        borderBottom: ` 3px solid ${colorArray[index]}`,
                      }}
                    >
                      {item.productName}
                    </span>
                    <span>
                      {(item && item.currency) || ""}
                      {item.totalSale}
                    </span>
                  </div>
                );
              })}
          </div>
          <RevenuePieChart topProducts={topProducts} />
        </div>
      </div>
    </Widget>
  );
};

export default TopProducts;
