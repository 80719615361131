import React, { useEffect, useState } from "react";
import { Upload, message, Button, Spin, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { DataGetAction } from "../../redux/actions/http";

const Index = ({
  url,
  type,
  setState,
  state,
  setCategoryValue,
  categoryValue,
  setSubCategoryValue,
  subCategoryValue,
  setOpen,
}) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const teamProps = useSelector((state) => state.categoryR);
  const iuProps = useSelector((state) => state.productR);
  const { SubCategoryList } = iuProps;
  const { Category } = teamProps;
  const props = {
    name: "file",
    action: `${process.env.REACT_APP_ROOT_URL}/${url}`,
    data: {
      category: categoryValue,
      subCategory: subCategoryValue,
    },
    headers: {
      authorization: token,
    },
    onChange(info) {
      setState(true);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        setState(true);
      }
      if (info.file.status === "done") {
        dispatch(
          DataGetAction(type, { query: "status", status: "approved" }, "")
        );
        setState(false);
        setOpen(false);
        setCategoryValue(null);
        setSubCategoryValue(null);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const fetchCategoryList = () => {
    dispatch(
      DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList")
    );
  };
  useEffect(fetchCategoryList, []);

  const onCategoryChange = (e) => {
    dispatch(
      DataGetAction("getSubCategory", { catId: e }, "fetchingSubCategoryList")
    );
  };

  const renderCategoryOption = () => {
    if (Category && Category.length > 0) {
      return Category.map((Item) => {
        return <Select.Option value={Item._id}>{Item.name}</Select.Option>;
      });
    }
  };
  const renderSubCategoryOption = () => {
    if (SubCategoryList && SubCategoryList.length > 0) {
      return SubCategoryList.map((Item) => {
        return <Select.Option value={Item._id}>{Item.name}</Select.Option>;
      });
    }
  };

  return (
    <div
      style={{ width: "100%", flexDirection: "column" }}
      className="gx-d-flex"
    >
      <span className="gx-my-3">
        Please select category and sub-category then file upload
      </span>
      <span className="gx-mb-1">Category</span>
      <Select
        value={categoryValue}
        onChange={(e) => {
          setCategoryValue(e);
          onCategoryChange(e);
        }}
      >
        {renderCategoryOption()}
      </Select>
      <span className="gx-mb-1 gx-mt-3">Sub Category</span>
      <Select value={subCategoryValue} onChange={(e) => setSubCategoryValue(e)}>
        {renderSubCategoryOption()}
      </Select>
      <span className="gx-mb-2 gx-mt-3 gx-mr-2">
        Upload CSV{"   "}(
        <a href="https://bevoe-assets.s3.eu-west-2.amazonaws.com/1701776545876-sample-csv.csv">
          Sample File
        </a>
        )
      </span>
      <Upload
        disabled={
          state || (categoryValue === null && subCategoryValue === null)
        }
        showUploadList={false}
        {...props}
      >
        {state && (
          <Spin>
            <Button
              style={{ width: "100%", height: "100px" }}
              className="gx-mr-2 gx-pt-0 gx-d-flex gx-align-items-center gx-justify-content-center"
            >
              <UploadOutlined /> Import
            </Button>
          </Spin>
        )}
        {!state && (
          <Button
            style={{ width: "100%", height: "100px" }}
            disabled={categoryValue === null && subCategoryValue === null}
            className="gx-mr-2 gx-pt-0 gx-d-flex gx-align-items-center gx-justify-content-center"
          >
            <UploadOutlined /> Import
          </Button>
        )}
      </Upload>
    </div>
  );
};

export default Index;
