import React from "react";
import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { DeletedRecord } from "redux/actions/Common";
const { confirm } = Modal;
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const DeleteConfirm = ({
  title,
  content,
  buttonName,
  selectedItem,
  type,
  Icon,
  method = "DELETE",
  okText = "Delete",
  cancelText = "Cancel",
  fontSize,
}) => {
  const dispatch = useDispatch();

  const showDeleteConfirm = () => {
    confirm({
      title: title,
      icon: <QuestionCircleOutlined />,
      content: content,
      okText: okText,
      okType: "primary",
      cancelText: cancelText,
      confirmLoading: true,

      onOk() {
        return new Promise((resolve, reject) => {
          const token = localStorage.getItem("token");
          fetch(`${ROOT_URL}/${type}`, {
            method: method,
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: token,
            }),
            body: JSON.stringify(selectedItem),
          })
            .then((response) => {
              response.json().then((response) => {
                dispatch(DeletedRecord(type, response));
                resolve();
              });
            })
            .catch((err) => {
              reject();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <span
      className="gx-link gx-pointer deleteButton"
      style={{ fontSize: fontSize ? fontSize : "20px" }}
      onClick={showDeleteConfirm}
    >
      {" "}
      {Icon ? Icon : buttonName}{" "}
    </span>
  );
};

export default DeleteConfirm;
