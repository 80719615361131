import React, {useEffect} from 'react'
import DashboardData from 'components/Dashboard/Admin'
import Layout from 'components/LayoutForPortal'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'

const Index = () => {

    const dispatch = useDispatch();

    const fetchDashboardData = () => {
        dispatch(DataGetAction('getAdminDashboardOverView', '', 'fetchingData'));
        dispatch(DataGetAction('getTopPerFormingVendor', '', 'fetchingData'));
    }

    useEffect(fetchDashboardData, [])


    const dashProps = useSelector(state => state.adminDashboard)


    const { dashboardData,topPerformingVendors } = dashProps

    return (
        <Layout>
            <DashboardData dashboardData={dashboardData} topPerformingVendors={topPerformingVendors}/>
        </Layout>
    )
}

export default Index;