import React, { useEffect } from 'react'
import { Button, Form, Input, Spin, message } from 'antd'
import Widget from '../../Widget'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { DataRequestAction, DataGetAction } from 'redux/actions/http';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import swal from 'sweetalert';
const Index = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const { token } = params;


    const authProp = useSelector(state => state.auth);
    const manageUserProps = useSelector(state => state.manageUser);
    const { userRegisterSuccessfully, userRegisterFail, savingDetail } = authProp
    const { userDetail, userDetailFetchLoader, userDetailFetchError, textMessage } = manageUserProps;


    const fetchUserDetail = () => {
        dispatch(DataGetAction('getUserDetail', { guid: token }, 'fetchingDetail'));
    }

    useEffect(fetchUserDetail, [])

    const redirectToLogin = () => {
        history.push('/login')
    }

    const onFinish = (values) => {
        values._id = userDetail._id;
        values.status = "active"
        dispatch(DataRequestAction('POST', 'signUp', values, '', 'savingUserDetail', false))
    }

    if (userRegisterSuccessfully) {

        swal({
            icon: "success",
            title: "Thanks",
            text: "You have successfully Activate you account. Click Okay to continue",
            className: "userDetailAlert"
        }).then(() => {
            history.push("/login")
        });

        dispatch({
            type: "update_auth"
        })
    }

    if (userRegisterFail) {
        message.error("some error occur while updating the info. kindly try again")
        dispatch({
            type: "update_auth"
        })
    }

    const renderSignUpForm = () => {

        if (userDetailFetchLoader && !userDetailFetchError) {
            return (
                <div>
                    <div className="welcomeMessage gx-text-center gx-mb-5">
                        <h2 className="gx-font-weight-bold gx-mb-0 gx-font-sans-bold gx-blue-font gx-font-35">Sign up</h2>
                        <p className="gx-mt-3 gx-font-gray gx-font-sans-regular gx-blue-font gx-font-35">Fetching Detail <Spin size="large" color="blue" style={{
                            marginLeft: 15
                        }} /></p>
                    </div>
                </div>
            )
        } else if (userDetailFetchError) {
            return <div>
                <div className="welcomeMessage gx-text-center gx-mb-5">
                    <p className="gx-mt-3 gx-font-gray gx-font-sans-regular gx-blue-font gx-font-35">
                        {textMessage}
                    </p>
                    <Button className="gx-pt-0" onClick={() => redirectToLogin()}>Login</Button>
                </div>
            </div>
        } else {
            return (
                <>
                    <div className="welcomeMessage gx-text-center gx-mb-5">
                        <h2 className="gx-font-weight-bold gx-mb-0 gx-font-sans-bold gx-blue-font gx-font-35">Sign up</h2>
                        <p className="gx-mt-3 gx-font-gray gx-font-sans-regular">Set up your account in our portal</p>
                    </div>
                    <Form
                        name="basic"
                        initialValues={userDetail}
                        onFinish={onFinish}
                    >

                        <Form.Item name={'firstName'} className="gx-ml-0 gx-mr-0" rules={[{
                            required: true,
                            message: 'Please input your Name',
                        }]}>
                            <Input placeholder="First Name" prefix={<UserOutlined style={{
                                marginRight: '5px'
                            }} />} />
                        </Form.Item>
                        <Form.Item name={'lastName'} className="gx-ml-0 gx-mr-0" rules={[{
                            required: true,
                            message: 'Please input your Name',
                        }]}>
                            <Input placeholder="Last Name" prefix={<UserOutlined style={{
                                marginRight: '5px'
                            }} />} />
                        </Form.Item>
                        <Form.Item name={'email'} className="gx-ml-0 gx-mr-0" rules={[{
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        }]}>
                            <Input placeholder="Email" disabled prefix={<MailOutlined style={{
                                marginRight: '5px'
                            }} />} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            className="gx-ml-0 gx-mr-0"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" prefix={<LockOutlined style={{
                                marginRight: '5px'
                            }} />} />
                        </Form.Item>
                        <Form.Item
                            name="confPassword"
                            className="gx-ml-0 gx-mr-0"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Confirm Password" prefix={<LockOutlined style={{
                                marginRight: '5px'
                            }} />} />
                        </Form.Item>
                        <div
                            className="gx-d-flex gx-mt-5  gx-flex-column gx-align-items-center">
                            {!savingDetail && <Button
                                type="primary"
                                className="login-form-button gx-pt-0 gx-w-75 gx-mr-0 gx-font-sans-regular"
                                htmlType="submit"
                            >Submit</Button>}
                            {savingDetail && <Button
                                type="primary"
                                className="login-form-button spinner gx-pt-0 gx-w-75 gx-mr-0 gx-font-sans-regular"
                            ><span className="gx-mr-1">Submit</span><Spin color="white-spin"/></Button>}

                            <p className="gx-font-sans-regular">Already have an account?
                        <span onClick={() => redirectToLogin()} style={{
                                    cursor: 'pointer',
                                    color: '#3FD4A2',
                                    marginLeft: '4px'
                                }}>Sign In</span></p>
                        </div>
                    </Form>

                </>
            )
        }

    }


    return (
        <Widget styleName="gx-paddingAuth">
            <div className="SignInVerification gx-p-5">
                {renderSignUpForm()}
            </div>
        </Widget>
    )
}

export default Index
