import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import OrderList from "components/Table/BasicTable";
import TableAction from "components/Table/Actions";
// import AddOrderForm from './components/AddOrderForm'
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "redux/actions/http";
import { message, DatePicker, Input, Modal, Form, Button, Spin } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const Index = () => {
  const dispatch = useDispatch();
  const teamProps = useSelector((state) => state.orderR);
  const params = useParams();
  const { query } = params;

  const {
    success,
    error,
    textMessage,
    showDeliveredModal,
    selectedOrder,
    showOTPSpinner,
  } = teamProps;

  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction(
        "orderList",
        { query: "status", status: query },
        "fetchingOrderList"
      )
    );
    dispatch({
      type: "orderBadge_SUCCESS",
    });
  };
  useEffect(fetchTeamUsers, [query]);

  const onChange = (dates, dateStrings) => {
    if (dates && dates.length) {
      dispatch(
        DataGetAction(
          "orderList",
          {
            query: "search",
            key: "date",
            startDate: dates[0],
            endDate: dates[1],
          },
          "fetchingOrderList"
        )
      );
    }
  };

  const renderItemName = (data) => {
    const orderItems = data.orderItemsInfo;
    const OrderProduct = [];
    orderItems &&
      orderItems.map((Item) => {
        OrderProduct.push(Item.productName && Item.productName.toUpperCase());
        return Item;
      });
    return OrderProduct.toString();
  };

  const renderItemQty = (data) => {
    const orderItems = data.orderItemsInfo;
    const OrderQty = [];
    orderItems &&
      orderItems.map((Item) => {
        OrderQty.push(Item.orderQty);
        return Item;
      });
    return OrderQty.toString();
  };

  const columns = [
    {
      title: "OrderId",
      render: (record) => <span>{record.orderNo}</span>,
    },
    {
      title: "Product Name",
      render: (record) => <span>{renderItemName(record)}</span>,
    },
    {
      title: "Qty",
      render: (record) => <span>{renderItemQty(record)}</span>,
    },
    {
      title: "Customer Name",
      render: (record) => <span>{record.orderUserName}</span>,
    },
    {
      title: "Email",
      render: (record) => <span>{record.orderUserEmail}</span>,
    },
    {
      title: "Phone No",
      render: (record) => <span>{record.orderUserPhoneNo}</span>,
    },
    {
      title: "Date & Time",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")}</span>
      ),
    },
    {
      title: "Status",
      render: (record) => <span>{record.status}</span>,
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      width: "90px",
    },
  ];

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetOrderState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetOrderState",
    });
  }

  const closeModal = () => {
    dispatch({
      type: "showDeliveredModal",
      payload: null,
    });
  };

  const onFinish = (value) => {
    selectedOrder.otp = value.otp;
    dispatch(
      DataRequestAction(
        "PUT",
        "updateOrderStatus",
        selectedOrder,
        "",
        "updatingOrderStatus"
      )
    );
  };

  const updateOrderStatus = (record, status) => {
    record.status = status;
    if (record.status !== "delivered") {
      dispatch(
        DataRequestAction(
          "PUT",
          "updateOrderStatus",
          record,
          "",
          "updatingOrderStatus"
        )
      );
    } else {
      dispatch({
        type: "showDeliveredModal",
        payload: record,
      });
    }
  };
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.status === "pending" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateOrderStatus(record, "preparing")}
          >
            Accept
          </span>
        )}
        {(record.status === "preparing" || record.status === "ready") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateOrderStatus(record, "ready")}
          >
            Ready
          </span>
        )}
        {record.status === "ready" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateOrderStatus(record, "delivered")}
          >
            delivered
          </span>
        )}
        {record.status === "pending" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateOrderStatus(record, "cancel")}
          >
            Cancel
          </span>
        )}
      </div>
    );
  };

  const { Order } = teamProps;

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        <Title title="Order" />
        <div className="gx-d-flex">
          <div className="filters" style={{ marginRight: "12px" }}>
            <RangePicker onChange={onChange} className="gx-mr-3" />

            {/* <CallFilters isShowingAll={true} apiName="userList" searchKey="category" options={[
                            { status: "active", label: "Active" },
                            { status: "pending", "label": "Invited" },
                            { status: "block", label: "Block" }
                        ]} placeholder="Filter by category" ItemValue="status" label="label" /> */}

            <Input.Search
              showSearch
              placeholder="search by orderId"
              filterOption={false}
              onChange={(e) =>
                dispatch(
                  DataGetAction("orderList", { orderId: e.target.value }, "")
                )
              }
              style={{ width: "200px", marginLeft: "12px" }}
            />
          </div>
        </div>
      </div>
      <OrderList data={Order} columns={columns} />
      <Modal
        visible={showDeliveredModal}
        closeIcon={<CloseCircleOutlined />}
        centered
        onCancel={() => closeModal()}
        title="OTP Verification"
        footer={false}
      >
        <Form layout="vertical" name="form_in_modal" onFinish={onFinish}>
          <Form.Item
            label="Order OTP"
            name="otp"
            rules={[
              { required: true, message: "Please input your Order OTP!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="gx-d-flex gx-justify-content-end">
              {!showOTPSpinner && (
                <Button type="primary" className="gx-pt-0" htmlType="submit">
                  Verify & Delivered
                </Button>
              )}
              {showOTPSpinner && (
                <Spin className="gx-mb-0">
                  {" "}
                  <Button
                    type="primary"
                    className="gx-pt-0"
                    htmlType="button"
                    disabled
                  >
                    Verify & Delivered
                  </Button>
                </Spin>
              )}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Index;
