import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { renderStatus } from "../../../components/utils/commonUseFunction";
import { DataGetAction } from "redux/actions/http";
import TableAction from "components/Table/Actions";
import ResetUserPasswordModel from "./components/ResetUserPasswordForm";
import UserChartComponent from "./components/UserChartComponent";
import UserList from "components/Table/BasicTable";
import DeleteConfirm from "../../../components/Modal/DeleteConfirm";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const usersProps = useSelector((state) => state.usersR);
  const { Users } = usersProps;
  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction("getUsersList", { query: "all" }, "fetchingUsersList")
    );
  };

  useEffect(fetchTeamUsers, []);
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.userId}</span>,
    },
    {
      title: "Name",
      render: (record) => (
        <span
          className="gx-text-capitalize gx-link"
          onClick={() => history.push(`/users/viewFinancialReport/${record._id}`)}
        >
          {record.firstName || "---"} { "" } {record.lastName || "---"}
        </span>
      ),
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
    },
    {
      title: "Status",
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status) || "---"}
        </span>
      ),
      key: "status",
    },
    {
      title: "Mobile Type",
      render: (record) => <span>{record.mobileType || "---"}</span>,
    },
    {
      title: "Signup Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")}</span>
      ),
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      width: "90px",
    },
  ];

  const resetPassword = (record) => {
    dispatch({
      type: "ResetUserPassword_Modal",
      payload: record,
    });
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.status === "active" && (
          <DeleteConfirm
            method="PUT"
            title="Are you sure you want to block this user"
            okText="Yes"
            cancelText="No"
            type="updateUserStatus"
            buttonName="Block"
            fontSize="14px"
            selectedItem={{ _id: record._id, status: "block" }}
          />
        )}
        {(record.status === "block" || record.status === "delete") && (
          <DeleteConfirm
            method="PUT"
            title="Are you sure you want to active this user"
            okText="Yes"
            cancelText="No"
            type="updateUserStatus"
            buttonName="Active"
            fontSize="14px"
            selectedItem={{ _id: record._id, status: "active" }}
          />
        )}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => resetPassword(record)}
        >
          Reset Password
        </span>
      </div>
    );
  };

  

  return (
    <Layout>
      <UserChartComponent />
      <div className="gx-d-block gx-flex-row gx-justify-content-between gx-my-2">
        <Title title="Users" />
        <div className="filters" style={{ marginRight: "12px" }}>
          <Input.Search
            showSearch
            placeholder="Search by name/email"
            filterOption={false}
            onChange={(e) =>
              dispatch(
                DataGetAction("getUsersList", { name: e.target.value }, "")
              )
            }
            style={{ width: "250px", marginLeft: "12px" }}
          />
        </div>
      </div>

      <UserList data={Users} columns={columns} />
      <ResetUserPasswordModel />
    </Layout>
  );
};

export default Index;
