import React, { useState } from "react";
import { Menu } from "antd";
//import {Link} from "react-router-dom";
import _ from "lodash";
import SidebarLogo from "./SidebarLogo";

import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import MenuList from "./Menu";
const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  const location = useLocation();

  const logInUser = useSelector((state) => state.auth.authUser);
  const bProps = useSelector((state) => state.badgeR);

  const role = logInUser ? logInUser.role : "superAdmin";
  const MenuData = MenuList[role];
  const [changeSelectedKey, setSelectedKey] = useState("");

  const renderBadge = (Item) => {
    if (bProps[`${Item.badgeType}`] && location.pathname !== Item.url) {
      return (
        <span className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white custom-badge">
          New
        </span>
      );
    }
  };

  const singleMenuItem = (Item) => {
    return (
      <Menu.Item key={Item.url}>
        <Link
          to={{ pathname: Item.url, state: { key: Item.key } }}
          key={Item.url + "key"}
        >
          <i
            className={`icon icon-${Item.icon}`}
            style={{
              top: Item.top,
              position: "relative",
            }}
          />
          <span>
            {Item.title} {Item.badges ? renderBadge(Item) : ""}
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const renderSubMenu = (Item) => {
    return (
      <SubMenu
        key={Item.title}
        onTitleClick={() => {
          setSelectedKey(Item.title);
        }}
        title={
          <span className="gx-justify-content-between">
            {" "}
            <span>
              <i className={`icon icon-${Item.icon}`} />
              <span>{Item.title}</span>{" "}
            </span>
            {Item.badges ? (
              <span className="d-flex">{renderBadge(Item)}</span>
            ) : (
              ""
            )}
          </span>
        }
      >
        {Item.subMenu.map((x) => {
          return singleMenuItem(x);
        })}
      </SubMenu>
    );
  };

  const getPermission = (userInfo) => {
    const Result = [];
    const permission = userInfo.permission;
    let permissionOnly = _.omitBy(permission, (v) =>
      _.isBoolean(v) || _.isFinite(v) ? false : _.isEmpty(v)
    );
    const allKeys = _.keys(permissionOnly);
    let notData = [
      "_id",
      "createdAt",
      "roleName",
      "__v",
      "roleColor",
      "userCount",
      "createdBy",
      "roleType",
    ];
    allKeys.map((Item) => {
      if (!notData.includes(Item)) {
        Result.push(Item);
      }
      return Item;
    });
    return Result;
  };

  const renderMenu = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo.role === "adminTeam") {
      const Permission = getPermission(userInfo);
      return MenuData.map((Item) => {
        if (Permission.includes(Item.permissionKey)) {
          if (Item.subMenu) {
            return renderSubMenu(Item);
          } else {
            return singleMenuItem(Item);
          }
        } else {
          return <span className="empty"></span>;
        }
      });
    } else if (userInfo.role === "customerTeam") {
      const Permission = userInfo.permission.permission;
      return MenuData.map((Item) => {
        if (Permission.includes(Item.permissionKey)) {
          if (Item.subMenu) {
            return renderSubMenu(Item);
          } else {
            return singleMenuItem(Item);
          }
        } else {
          return <span className="empty"></span>;
        }
      });
    } else {
      return MenuData.map((Item) => {
        if (Item.subMenu) {
          return renderSubMenu(Item);
        } else {
          return singleMenuItem(Item);
        }
      });
    }
  };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className="gx-layout-sider-scrollbar gx-mt-4">
          <Menu
            defaultOpenKeys={location.state && location.state.key}
            defaultSelectedKeys={[location.pathname]}
            selectedKeys={[location.pathname]}
            openKeys={[changeSelectedKey, location.state && location.state.key]}
            theme="dark"
            mode="inline"
          >
            {renderMenu()}
          </Menu>
        </div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
