import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import CategoryList from "components/Table/BasicTable";
import TableAction from "components/Table/Actions";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import AddCategoryForm from "./components/AddCategoryForm";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import { message, DatePicker, Input } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;
const Index = () => {
  const dispatch = useDispatch();
  const teamProps = useSelector((state) => state.categoryR);
  console.log("====teamProps====", teamProps);

  const { success, error, textMessage } = teamProps;

  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList")
    );
  };
  useEffect(fetchTeamUsers, []);

  const onChange = (dates, dateStrings) => {
    dispatch(
      DataGetAction(
        "categoryList",
        {
          query: "search",
          key: "date",
          stateDate: dates[0],
          endDate: dates[1],
        },
        "gettingInspectionHistory"
      )
    );
 
  };
  const columns = [
    {
      title: "Name",
      render: (record) => <span>{record.name}</span>,
    },
    {
      title: "Added By",
      render: (record) => <span>{record.addedBy || ""}</span>,
    },
    {
      title: "Date & Time",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")}</span>
      ),
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      width: "90px",
    },
  ];

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetCategoryState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetCategoryState",
    });
  }

  const editRecord = (record) => {
    dispatch({
      type: "AddCategory_Modal",
      payload: record,
    });
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <span style={{ cursor: "pointer" }} onClick={() => editRecord(record)}>
          Edit
        </span>
        <DeleteConfirm
          type="deleteCategory"
          method="DELETE"
          selectedItem={record}
          buttonName="Yes"
          title="Are you sure you want to delete this category?"
          content=""
          Icon={<span style={{ fontSize: "14px" }}>Delete</span>}
        />
      </div>
    );
  };

  const { Category } = teamProps;

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        <Title title="Category" />
        <div className="gx-d-flex">
          <div className="filters" style={{ marginRight: "12px" }}>
            <RangePicker onChange={onChange} className="gx-mr-3" />
            <Input.Search
              showSearch
              placeholder="Search by name"
              filterOption={false}
              onChange={(e) =>
                dispatch(
                  DataGetAction("categoryList", { name: e.target.value }, "")
                )
              }
              style={{ width: "200px", marginLeft: "12px" }}
            />
          </div>
          <AddCategoryForm initialRecord={{ name: "" }} />
        </div>
      </div>
      <CategoryList data={Category} columns={columns} />
    </Layout>
  );
};

export default Index;
