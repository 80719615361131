import React from "react";
import { Select } from "antd";
import { DataGetAction } from "redux/actions/http";
import { useDispatch } from "react-redux";
const CallFilters = ({
  showAll,
  placeholder,
  marginRight,
  options,
  ItemValue,
  searchId,
  label,
  apiName,
  searchKey,
  isShowingAll,
  className,
}) => {
  const dispatch = useDispatch();
  const handleChange = (data) => {
    const query = { query: "search", key: searchKey, value: data };
    if (data === "all") {
      query.query = "all";
      if (showAll) {
        return showAll();
      }
    }
    dispatch(DataGetAction(apiName, query, "searching"));
  };

  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
      className={className}
      style={{ width: "200px", marginRight: marginRight }}
    >
      {isShowingAll && <Select.Option value="all">All</Select.Option>}
      {options &&
        options.map((Item) => {
          return (
            <Select.Option value={Item[`${ItemValue}`]}>
              {Item[`${label}`]}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default CallFilters;
