import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import UserList from "components/Table/BasicTable";
import TableAction from "components/Table/Actions";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import InviteUser from "./components/inviteUser";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "redux/actions/http";
import { message, DatePicker, Input, Button } from "antd";
import ResetPassword from "./components/ResetPassword";
import { useHistory } from "react-router-dom";
import VendorChartComponent from "./components/VendorChartComponent";

//const SECRET_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const { RangePicker } = DatePicker;
const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const teamProps = useSelector((state) => state.manageUser);

  const {
    success,
    error,
    textMessage,
    initialValues,
    ShowModal,
    deleteVendorSuccess,
  } = teamProps;

  const { successReinvite } = useSelector((state) => state.reportR);
  if (deleteVendorSuccess) {
    dispatch(DataGetAction("getVendorCards"));
    dispatch({ type: "resetUserInvitationState" });
  }
  useEffect(() => {
    if (successReinvite) {
      message.success("Reinvite is success");
    }
  }, [successReinvite]);

  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction("vendorList", { query: "all" }, "fetchingVendorList")
    );
    dispatch({
      type: "vendorBadge_SUCCESS",
    });
  };
  useEffect(fetchTeamUsers, []);

  const openResetModal = (record) => {
    dispatch({
      type: "ResetPassword_Modal",
      payload: record,
    });
  };

  const renderStatus = (data) => {
    if (data.status === "pending") {
      return (
        <span
          style={{
            color: "#FFB800",
          }}
        >
          Pending
        </span>
      );
    } else if (data.status === "active") {
      return (
        <span
          style={{
            color: "#3FD4A2",
          }}
        >
          Active Account
        </span>
      );
    } else if (data.status === "invited") {
      return (
        <span
          style={{
            color: "#0A7DD9",
          }}
        >
          Invited
        </span>
      );
    } else if (data.status === "block") {
      return (
        <span
          style={{
            color: "#EE0C2B",
          }}
        >
          Blocked
        </span>
      );
    }
  };

  const onChange = (dates, dateStrings) => {
    if (dates && dates.length) {
      dispatch(
        DataGetAction(
          "vendorList",
          {
            query: "search",
            key: "date",
            startDate: dates[0],
            endDate: dates[1],
          },
          "gettingInspectionHistory"
        )
      );
    }
  };
  const updateStatus = (userId, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "reinviteVendor",
        { userId: userId, status: status },
        "",
        "",
        ""
      )
    );
  };

  const columns = [
    {
      title: "Name",
      render: (record) => (
        <span
          className="gx-text-capitalize gx-link"
          onClick={() => history.push(`/admin/vendorDashboard/${record._id}`)}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: "Email",
      render: (record) => <span>{record.email}</span>,
    },
    {
      title: "Store Name",
      render: (record) => <span>{record.storeName}</span>,
    },
    {
      title: "Store Code",
      render: (record) => <span>{record.storeShortCodeKey}</span>,
    },
    {
      title: "Category",
      render: (record) => <span>{record.storeCategoryName}</span>,
    },
    {
      title: "Status",
      render: (record) => {
        return renderStatus(record);
      },
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      width: 90,
    },
  ];

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetUserInvitationState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetUserInvitationState",
    });
  }

  const editRecord = (record) => {
    dispatch({
      type: "InviteUser_Modal",
      payload: record,
    });
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.status === "active" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => editRecord(record)}
          >
            Edit
          </span>
        )}
        {record.status === "invited" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              updateStatus(record._id, "reinvited");
            }}
          >
            Reinvited
          </span>
        )}
        {record.status === "active" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => openResetModal(record)}
          >
            <span>Reset Password</span>
          </span>
        )}
        <DeleteConfirm
          type="deleteVendor"
          method="DELETE"
          selectedItem={record}
          buttonName="Yes"
          title="Are you sure you want to delete this vendor?"
          content=""
          Icon={<span style={{ fontSize: "14px" }}>Delete</span>}
        />
      </div>
    );
  };

  const { Users } = teamProps;

  return (
    <Layout>
      <VendorChartComponent />
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        <Title title="Vendor" />
        <div className="gx-d-flex">
          <div className="filters" style={{ marginRight: "12px" }}>
            <RangePicker onChange={onChange} className="gx-mr-3" />
            <Input.Search
              showSearch
              placeholder="Search by name"
              filterOption={false}
              onChange={(e) =>
                dispatch(
                  DataGetAction(
                    "vendorList",
                    { query: "search", name: e.target.value },
                    ""
                  )
                )
              }
              style={{ width: "220px", marginLeft: "12px" }}
            />
          </div>
          <Button
            type="primary"
            className="gx-pt-0"
            onClick={() => {
              dispatch({
                type: "InviteUser_Modal",
                payload: null,
              });
            }}
          >
            Add Vendor
          </Button>
          {ShowModal && <InviteUser initialRecord={initialValues} />}
        </div>
      </div>
      <UserList data={Users} columns={columns} />
      <ResetPassword />
    </Layout>
  );
};

export default Index;
