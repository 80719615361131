
import React from 'react'
import { Modal } from 'antd';
import { useDispatch } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons';
const SimpleModal = (props) => {

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch({
            type: props.modalFor,
            payload: null
        })
    }

    return (
        <Modal
            visible={props.visible}
            closeIcon={<CloseCircleOutlined />}
            centered
            onCancel={() => closeModal()}
            footer={false}
            title={props.title}
        >
            {props.children}
        </Modal>

    )
}

export default SimpleModal
