const INIT_STATE = {
  Users: [],
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
  userStatusSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "editUsers_SUCCESS": {
      const usersArray = getUsersArray(state.Users, action.payload.Record);
      return {
        ...state,
        Users: [...usersArray],
        success: true,
        textMessage: "Users record updated successfully",
      };
    }
    case "getUsersList_SUCCESS": {
      return {
        ...state,
        Users: action.payload.Record,
        userData: action.payload.Record,
      };
    }

    case "getUserCards_SUCCESS": {
      return {
        ...state,
        cardsData: action.payload.Record,
      };
    }
    case "getUserName_SUCCESS": {
      return {
        ...state,
        userName: action.payload.Record,
      };
    }

    case "updateUserStatus_SUCCESS": {
      const usersStatusArr = getUserStatusArray(
        state.Users,
        action.payload.Record
      );
      return {
        ...state,
        Users: [...usersStatusArr],
        addingUsers: false,
        userStatusSuccess: true,
        success: true,
        textMessage: "User updated successfully",
      };
    }

    case "resetUserPassword_SUCCESS": {
      return {
        ...state,
        success: true,
        textMessage: "User Password set successfully",
        ShowModal: false,
      };
    }

    case "resetUserPassword_FAILURE": {
      return {
        ...state,
        success: false,
        error: true,
        textMessage:
          "some error occur while changing user password. kindly try again",
      };
    }
    case "ResetState": {
      return {
        ...state,
        userStatusSuccess: false,
      };
    }

    case "ResetUserPassword_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: action.payload,
        success: true,
      };
    }
    case "getUserMonthlySpending_SUCCESS": {
      return {
        ...state,
        MonthlySpending: action.payload.Record,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getUserStatusArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function getUsersArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
