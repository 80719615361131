import React, { useRef, useCallback } from "react";
import { Form, Row, Col, Select } from "antd";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import Geocode from "react-geocode";
const { Option } = Select;
const containerStyle = {
  width: "100%",
  height: "250px",
  padding: "40px",
};

const libraries = ["places"];
const SECRET_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const GoogleMapComponent = ({ setAddressObj, addressObj }) => {
  const mapRef = useRef();
  Geocode.setApiKey(SECRET_KEY);
  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: SECRET_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const zipCode = await getZipCode(results[0]);

      let obj = {
        address: address,
        lat: lat,
        lng: lng,
        zipCode: zipCode ? zipCode : "",
      };
      setAddressObj(obj);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error:d ", error);
    }
  };

  const onMapClick = useCallback((e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        const address = response.results[0].formatted_address;
        handleSelect(address);
      },
      (error) => {
        console.log(error, e.latLng.lat(), e.latLng.lng());
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please choose your address!" }]}
          >
            <Select
              style={{ width: "100%" }}
              showSearch
              value={value}
              onSearch={(e) => setValue(e)}
              onSelect={(value) => {
                handleSelect(value);
              }}
            >
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <Option key={id} value={description}>
                    {description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: addressObj ? addressObj.lat : 42.9849233,
            lng: addressObj ? addressObj.lng : -81.2452768,
          }}
          zoom={12}
          onLoad={onLoad}
          onClick={onMapClick}

          //onUnmount={onUnmount}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <></>
        </GoogleMap>
      )}
    </>
  );
};

export default React.memo(GoogleMapComponent);
