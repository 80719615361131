import React from "react";
import Widget from "components/Widget";
import RevenuePieChart from "./RevenuePieChart";
const TopVendors = ({ topPerformingVendors, KeyName = "storeName" }) => {
  var colorArray = ["#C39FDF", "#3FD4A2", "#0A7DD9", "#B9E886"];
 
  return (
    <Widget
      styleName={"revenueOverview"}
      title={
        <h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">
          Top performance products
        </h4>
      }
    >
      <div className="gx-d-flex gx-justify-content-between">
        <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-p-5 gx-w-100">
          <div className="gx-mr-4 gx-w-75">
            {topPerformingVendors &&
              topPerformingVendors.map((item, index) => {
                return (
                  <div className="gx-d-flex  gx-mb-3 gx-mt-3">
                    <span
                      className="gx-font-gilory-medium gx-mr-2"
                      style={{
                        borderBottom: ` 3px solid ${colorArray[index]}`,
                      }}
                    >
                      {item[KeyName] || "---"}
                    </span>
                    <span>
                      {(item && item.currency) || ""}
                      {item.totalSale || 0}
                    </span>
                  </div>
                );
              })}
          </div>
          <RevenuePieChart topPerformingVendors={topPerformingVendors} />
        </div>
      </div>
    </Widget>
  );
};

export default TopVendors;
