const INIT_STATE = {
  ProductSaleReport: [],
  ProductsSaleReport: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "productSaleReport_SUCCESS": {
      return { ...state, ProductSaleReport: action.payload.Record };
    }
    case "productsSaleReport_SUCCESS": {
      return { ...state, ProductsSaleReport: action.payload.Record };
    }

    case "reinviteVendor_SUCCESS": {
      return { ...state, successReinvite: true };
    }
    case "getUserWithNoTransaction_SUCCESS": {
      return { ...state, StaticUsers: action.payload.Record };
    }

    default: {
      return { ...state };
    }
  }
};
