import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import Title from 'components/Title'
import ProductReportList from 'components/Table/BasicTable'

import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http';
import { DatePicker, Input } from 'antd'
import CallFilters from 'components/Filters/CallFilters'

const { RangePicker } = DatePicker;
const Index = () => {

    const dispatch = useDispatch();
    const reportProps = useSelector(state => state.reportR)


    const { ProductsSaleReport } = reportProps

    const fetchTeamUsers = () => {
        dispatch(DataGetAction('productsSaleReport', { query: "all" }, 'gettingProductSaleReport'));
    }
    useEffect(fetchTeamUsers, [])


    // const renderStatus = (data) => {
    //     if (data.status === "enable") {
    //         return <span style={{
    //             color: '#3FD4A2'
    //         }}>Invited</span>
    //     } else if (data.status === "disable") {
    //         return <span style={{
    //             color: '#EE0C2B'
    //         }}>Active Account</span>
    //     } else if (data.status === "block") {
    //         return <span style={{
    //             color: '#EE0C2B'
    //         }}>Blocked</span>
    //     }
    // }


    // const resendInvite = (record) => {
    //     dispatch(DataRequestAction('PUT', 'resentInvitationEmail', record, '', 'resentEmail', false));
    // }
    // const setUserStatus = (userId, status) => {
    //     dispatch(DataRequestAction('PUT', 'updateStatus', { userId, status }, '', 'userStatsUpdating', false));
    // }

    const onChange = (dates, dateStrings) => {
        dispatch(DataGetAction('inspectionHistory', { query: "search", key: "date", stateDate: dates[0], endDate: dates[1] }, 'gettingInspectionHistory'));
        // console.log('From: ', dates[0], ', to: ', dates[1]);
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    }

    const columns = [
        {
            title: 'ID',
            render: record => <span className="gx-link">{record.productNo}</span>,
        },
        {
            title: 'Product Name',
            render: record => <span>{record.name}</span>,
        },
        {
            title: 'Category Name',
            render: record => <span>{record.categoryName}</span>,
        },
        {
            title: 'Revenue',
            render: record => <span>{+record.totalPrice  *  +record.totalSale}</span>,
        },
        {
            title: 'Sale',
            render: record => <span>{record.totalSale}</span>,
        },
        

    ];










    return (
        <Layout>
            <div className="gx-d-block gx-flex-row gx-justify-content-between">
                <Title title="Reports > Product" />
                <div className="gx-d-flex">
                    <div className="filters" style={{ marginRight: "12px" }}>
                        <RangePicker onChange={onChange} className="gx-mr-3" />

                        <CallFilters isShowingAll={true} apiName="userList" searchKey="status" options={[
                            { status: "active", label: "Active" },
                            { status: "pending", "label": "Invited" },
                            { status: "block", label: "Block" }
                        ]} placeholder="Filter by Membership" ItemValue="status" label="label" />

                        <Input.Search
                            showSearch
                            placeholder="Name"
                            filterOption={false}
                            onChange={(e) => dispatch(DataGetAction('categoryList', { name: e.target.value }, ''))}
                            style={{ width: '220px', marginLeft: '12px' }}
                        />
                    </div>
                </div>

            </div>
            <ProductReportList data={ProductsSaleReport} columns={columns} />

        </Layout>
    )
}

export default Index
