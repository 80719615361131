/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import { DataRequestAction } from "../../../../redux/actions/http";
import SimpleModal from "../../../../components/Modal/SimpleModal";
const UpdateSettings = () => {
  const dispatch = useDispatch();
  const settingProps = useSelector((state) => state.currencyR);
  const { Currency_Setting_Modal, currencyValues, currencyButtonSpin } =
    settingProps;
  const [initialDataSet, setInitialDataSet] = useState(null);
  const [form] = Form.useForm();
  let initialValues = null;
  initialValues = currencyValues;

  useEffect(() => {
    setInitialDataSet(initialValues);
    if (initialValues !== null) {
      form.setFieldsValue(initialDataSet);
    }
  }, [initialValues]);

  const onFinish = (values) => {
    if (initialDataSet && initialDataSet._id) {
      values._id = initialDataSet._id;
      dispatch(
        DataRequestAction(
          "PUT",
          "editCurrency",
          values,
          "",
          "currencyButtonSpin"
        )
      );
    } else {
      dispatch(
        DataRequestAction(
          "POST",
          "addCurrency",
          values,
          "",
          "currencyButtonSpin"
        )
      );
    }
  };
  return (
    <div>
      <span
        style={{ color: "grey" }}
        className="icon icon-edit gx-link"
        onClick={() => {
          dispatch({
            type: "Currency_Setting_Modal",
            payload: null,
          });
        }}
      ></span>
      <SimpleModal
        title={<span className='gx-font-sans-bold'>Currency Setting</span>}
        visible={Currency_Setting_Modal}
        modalFor="Currency_Setting_Modal"
      >
        {initialDataSet && (
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialDataSet}
          >
            <Form.Item
              name="currencyName"
              label="Currency Name"
              rules={[
                {
                  required: true,
                  message: "Please enter client name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="stripeCurrencyType"
              label="Stripe Currency Type"
              rules={[
                {
                  required: true,
                  message: "Please enter client name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="currencySymbol"
              label="Currency Symbol"
              rules={[
                {
                  required: true,
                  message: "Please enter email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="gx-d-flex gx-justify-content-end">
              <Button
                type="primary"
                className="gx-py-0"
                loading={currencyButtonSpin}
                disabled={currencyButtonSpin}
                htmlType="submit"
              >
                Update
              </Button>
            </div>
          </Form>
        )}
      </SimpleModal>
    </div>
  );
};

export default UpdateSettings;
