import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import ChartCard from "components/ChartCard";
import TopVendors from "./components/TopVendors";
import MonthlyAnalysis from "components/AreaChart";
import TotalRevenue from "./components/TotalRevenue";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import { useParams } from "react-router-dom";

const Index = () => {
  const dispatch = useDispatch();
  const [selectVendorId, setVendorId] = useState(null);
  const params = useParams();
  const adminProps = useSelector((state) => state.adminDashboard);
  const teamProps = useSelector((state) => state.manageUser);
  const { Users } = teamProps;
  const {
    TopPerFormingProducts,
    storeDashboardCards,
    RevenueData,
    MonthlyAnalysisForStoreSales,
  } = adminProps;

  const Data = [
    { name: "Page A", price: 200 },
    { name: "Page B", price: 1200 },
    { name: "Page C", price: 600 },
    { name: "Page D", price: 1600 },
    { name: "Page D", price: 1000 },
    { name: "Page H", price: 2260 },
    { name: "Page K", price: 800 },
  ];

  const fetchVendors = () => {
    dispatch(DataGetAction("vendorList", { query: "all" }));
  };
  useEffect(fetchVendors, []);
  const fetchDashboardData = (value) => {
    let vendorId = params && params.query;
    if (value) {
      vendorId = value;
    }
    setVendorId(vendorId);
    dispatch(DataGetAction("getStoreDashboardCards", { userId: vendorId }));
    dispatch(
      DataGetAction("getStoreTopPerFormingProducts", { userId: vendorId })
    );
    dispatch(DataGetAction("getTotalStoreRevenue", { userId: vendorId }));
    dispatch(
      DataGetAction("getMonthlyAnalysisForStoreSales", { userId: vendorId })
    );
  };
  useEffect(fetchDashboardData, []);

  return (
    <div className="gx-h-100">
      <div className="gx-w-100 gx-mb-3 gx-d-flex gx-align-items-center gx-justify-content-between">
        <h4 className="gx-mb-0">
          {storeDashboardCards &&
            storeDashboardCards.firstName + " " + storeDashboardCards.lastName}
        </h4>
        <Select
          value={selectVendorId}
          onChange={fetchDashboardData}
          placeholder="Select Vendor"
          style={{ width: "200px" }}
        >
          {Users &&
            Users.map((items) => (
              <Select.Option value={items._id}>{items.name}</Select.Option>
            ))}
        </Select>
      </div>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <ChartCard
                count={
                  (storeDashboardCards && storeDashboardCards.totalProduct) || 0
                }
                desc="No of total products"
                Data={Data}
                bgColor={"#F3ECF9"}
                borderColor={"#C39FDF"}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <ChartCard
                count={
                  (storeDashboardCards && storeDashboardCards.activeOrder) || 0
                }
                desc="No of active orders"
                Data={Data}
                bgColor={"#E5EEF5"}
                borderColor={"#59A6E5"}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <ChartCard
                count={
                  (storeDashboardCards && storeDashboardCards.activeProduct) ||
                  0
                }
                desc="No of active products"
                Data={Data}
                bgColor={"#E3F7D7"}
                borderColor={"#B8E986"}
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={24}>
              <ChartCard
                count={
                  (storeDashboardCards && storeDashboardCards.blockProduct) || 0
                }
                desc="No of blocked products"
                Data={Data}
                bgColor={"#FCEAF4"}
                borderColor={"#F8C8E3"}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Col xl={15} lg={15} md={12} sm={24} xs={24}>
                  <TopVendors
                    topPerformingVendors={TopPerFormingProducts}
                    KeyName="productName"
                  />
                </Col>
                <Col xl={9} lg={9} md={12} sm={24} xs={24}>
                  <TotalRevenue
                    totalRevenue={RevenueData && RevenueData.orderAmount}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <MonthlyAnalysis
                    data={MonthlyAnalysisForStoreSales}
                    width={"100%"}
                    height={180}
                    axisX={"name"}
                    axisY={"storeSales"}
                    title={"Monthly Analysis"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
