import React, { useEffect } from 'react'
import Widget from "components/Widget"
import RecentOrderList from "components/Table/BasicTable";
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http';
import moment from 'moment'
const RecentOrderReq = () => {


    const dispatch = useDispatch();
    const orderProps = useSelector(state => state.orderR)


    const { Order} = orderProps

    const fetchTeamUsers = () => {
        dispatch(DataGetAction('orderList', { query: "all" }, 'fetchingOrderList'));
    }
    useEffect(fetchTeamUsers, [])

    const renderItemName = (data) => {
        const orderItems = data.orderItemsInfo;
        const OrderProduct = [];
        orderItems && orderItems.map(Item => {
            OrderProduct.push( Item.productName && Item.productName.toUpperCase());
            return Item
        })
        return OrderProduct.toString();
    }

    const renderItemQty = (data) => {
        const orderItems = data.orderItemsInfo;
        const OrderQty = [];
        orderItems && orderItems.map(Item => {
            OrderQty.push(Item.orderQty);
            return Item
        })
        return OrderQty.toString();
    }

    const columns = [
        {
            title: 'OrderId',
            render: record => <span>{record.orderNo}</span>,
        },
        {
            title: 'Product Name',
            render: record => <span>{renderItemName(record)}</span>,
        },
        {
            title: 'Qty',
            render: record => <span>{renderItemQty(record)}</span>,
        },
        {
            title: 'Customer Name',
            render: record => <span>{record.orderUserName}</span>,
        },
        {
            title: 'Date & Time',
            render: record => <span>{moment(record.createdAt).format('Do MMM YYYY, h:mm a')}</span>,
        },
        {
            title: 'Status',
            key: 'status',
            render: (record) => renderStatus(record.status)
        },
        // {
        //     title: '',
        //     render: (record) => <TableAction> {renderActions(record)}</TableAction>,
        //     width: 60
        // },

    ];

    // const data = [
    //     {
    //         key: '1',
    //         clientName: 'Jeniffer L.',
    //         location : '32 The green london',
    //         orderTime : '26 March 2020, 12:42AM',
    //         quantity : 2,
    //         price : '$25',
    //         status : 'cancelled',
    //         transfer: '2 hrs. ago',
    //         image: "https://via.placeholder.com/150x150",
    //     },
    //     {
    //         key: '2',
    //         clientName: 'Jim Green',
    //         location : '544  captain america london',
    //         orderTime : '13 March 2020, 12:42AM',
    //         quantity : 3,
    //         status : 'pending',
    //         price : '$10',
    //         transfer: '17 days ago',
    //         image: "https://via.placeholder.com/150x150",

    //     },
    //     {
    //         key: '3',
    //         clientName: 'Joe Black',
    //         location : 'corner street london',
    //         orderTime : '09 Jul 2020, 12:42AM',
    //         quantity : 9,
    //         price : '$80',
    //         status : 'delivered',
    //         transfer: '1 month ago',
    //         image: "https://via.placeholder.com/150x150",
    //     },
    //     {
    //         key: '4',
    //         clientName: 'Mila Alba',
    //         location : 'upper house no 9 street london',
    //         orderTime : '12 Feb 2020, 12:42AM',
    //         quantity : 5,
    //         price : '$70',
    //         status : 'delivered',
    //         transfer: '1 month ago',
    //         image: "https://via.placeholder.com/150x150",
    //     }
    // ];

    // const renderActions = (record) => {
    //     return <div className="gx-d-flex gx-flex-column">
    //         <span className="gx-link">Accept Order</span>
    //         <span className="gx-link">Reject Order</span>
    //         <span className="gx-link">Delivered</span>
    //         <span className="gx-link">View Details</span>
    //     </div>
    // }

    const renderStatus = (status) => {
        if (status === "cancelled") {
            return <span  className="status_cancel">{status}</span>
        } 
        else if (status === "delivered") {
            return <span  className="status_delivered">{status}</span>
        } 
        else if (status === "pending") {
            return <span  className="status_pending">{status}</span>
        }
    }


    return (
        <Widget
            title={
                <h4 className="gx-blue-font gx-font-sans-bold gx-mb-2 gx-text-capitalize">Recent Order Requests</h4>
            }>
            <div className="gx-table-responsive">
                <RecentOrderList styleName="gx-table-no-bordered" columns={columns} data={Order} pagination={false}
                    size="small" />
            </div>
            <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-block gx-d-sm-none gx-mb-0 gx-mt-3">
                <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" /> Add New Account</p>
        </Widget>
    )
}

export default RecentOrderReq
