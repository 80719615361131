import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import ProductList from "components/Table/BasicTable";
// import DeleteConfirm from 'components/Modal/DeleteConfirm'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataGetAction } from "redux/actions/http";
import { message, DatePicker } from "antd";

const { RangePicker } = DatePicker;
const Index = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { query } = params;
  const teamProps = useSelector((state) => state.reportR);

  const { success, error, textMessage, ProductSaleReport } = teamProps;

  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction("productSaleReport", { query: "all" }, "fetchingReport")
    );
  };
  useEffect(fetchTeamUsers, [query]);

  const columns = [
    {
      title: "Product ID",
      render: (record) => <span>{record.productNo}</span>,
    },
    {
      title: "Product Name",
      render: (record) => <span>{record.name}</span>,
    },
    {
      title: "Category",
      render: (record) => <span>{record.categoryName}</span>,
    },
    {
      title: "Price",
      render: (record) => <span>{+record.totalPrice * +record.totalSale}</span>,
    },
    {
      title: "Total Sale",
      render: (record) => <span>{record.totalSale}</span>,
    },
  ];

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetProductState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetProductState",
    });
  }

  // const editRecord = (record) => {
  //     dispatch({
  //         type: "AddProduct_Modal",
  //         payload: record
  //     })
  // }

  // const updateProductStatus = (record, status) => {
  //     record.status = status;
  //     dispatch(DataRequestAction('PUT', 'updateProductStatus', record, '', 'updatingStatus'));
  // }

  // const renderActions = (record) => {
  //     if (record.status === "pending") {
  //         return <div className="gx-d-flex gx-flex-column">
  //             <span style={{ cursor: 'pointer' }} onClick={() => updateProductStatus(record, "approved")}>Approve</span>
  //             <span style={{ cursor: 'pointer' }} onClick={() => updateProductStatus(record, "reject")}>Reject</span>
  //         </div>
  //     } else {
  //         return <div className="gx-d-flex gx-flex-column">
  //             <span style={{ cursor: 'pointer' }} onClick={() => editRecord(record)}>Edit</span>
  //             <DeleteConfirm
  //                 type="deleteProduct"
  //                 method="DELETE"
  //                 selectedItem={record}
  //                 buttonName="Yes"
  //                 title="Are you sure you want to delete this product?"
  //                 content=""
  //                 Icon={<span style={{ fontSize: '14px' }}>Delete</span>}
  //             />
  //         </div>
  //     }
  // }

  const onChange = (dates, dateStrings) => {
    dispatch(
      DataGetAction(
        "productSaleReport",
        { query: "all", key: "date", stateDate: dates[0], endDate: dates[1] },
        "gettingProductSaleReport"
      )
    );
  };

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        <Title title="Report" />

        <div className="gx-d-flex gx-mb-3">
          <div className="filters">
            <RangePicker onChange={onChange} />
          </div>
        </div>
      </div>
      <ProductList data={ProductSaleReport} columns={columns} />
    </Layout>
  );
};

export default Index;
