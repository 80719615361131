const INIT_STATE = {
  showModal: false,
  settingValues: {
    firstName: "",
    lastName: "",
    email: "",
    storeName: "",
    phoneNumber: "",
  },
  success: false,
  textMessage: "",
  editSuccess: false,
  editLoader: false,
  editError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "getSetting_SUCCESS": {
      return {
        ...state,
        settingValues: action.payload.Record[0],
        success: true,
      };
    }

    case "editSettings_Modal": {
      return {
        ...state,
        showModal: !state.showModal,
        editSuccess: false,
        editLoader: false,
        editError: false,
      };
    }

    case "editSetting_SUCCESS": {
      return {
        ...state,
        showModal: !state.showModal,
        editSuccess: true,
        editLoader: false,
        editError: false,
        textMessage: "Information Updated Successfully",
      };
    }
    case "updateSettingState": {
      return {
        ...state,
        editSuccess: false,
        editLoader: false,
        editError: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};
