import React, { useEffect } from "react";
import Widget from "components/Widget";
import { Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { Empty } from "antd";
import { DataGetAction } from "../../../../redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
const TotalRevenue = ({ totalRevenue = 0 }) => {
  const dispatch = useDispatch();
  const { currencyValues } = useSelector((state) => state.currencyR);

  const getCurrencyApi = () => {
    dispatch(DataGetAction("getCurrency", "", ""));
  };
  useEffect(getCurrencyApi, []);

  const data = [{ name: "Revenue", value: totalRevenue }];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 70;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${currencyValues && currencyValues.currencySymbol}${value}`}</text>
      </g>
    );
  };

  return (
    <Widget
      title={
        <h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">
          Total Revenue
        </h4>
      }
    >
      <div className="gx-pt-4 gx-pb-4 h-100">
        <ResponsiveContainer width="100%" height={170}>
          {totalRevenue !== 0 ? (
            <PieChart>
              <Pie
                activeIndex={0}
                data={data}
                activeShape={renderActiveShape}
                dataKey="value"
                innerRadius={35}
                outerRadius={45}
                fill="#8884d8"
              />
            </PieChart>
          ) : (
            <Empty />
          )}
        </ResponsiveContainer>
      </div>
    </Widget>
  );
};

export default TotalRevenue;
