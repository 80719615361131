import React from 'react'
import logo from "assets/logo/bevoe-logo.png"
import authMap from 'assets/images/authMap.svg'
const Index = () => {
    return (
        <div className="gx-leftAuthSide">
            <div className="gx-text-center ">
                <img src={logo} alt="logo" className="gx-mb-2 gx-mt-5 main-logo" />
                <p className="gx-text-white">Let's Start Shopping!!!</p>
            </div>


            <div className="gx-bottomImage">
                <img src={authMap} alt="authMap" />
            </div>
        </div>
    )
}

export default Index
