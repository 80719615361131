const SocketResponseState =
  require("../redux/actions/socket").SocketResponseState;
const io = require("socket.io-client");
const Socket = (function () {
  let instance;
  function createInstance(userId) {
    const socket = io(process.env.REACT_APP_ROOT_URL, {
      query: `clientId=${userId}`,
    });
    socket.on("connect", function (err, data) {
      console.log(err, data);
    });

    socket.on("addNewProduct", function (data) {
      SocketResponseState("addNewProduct", { Record: data });
      SocketResponseState("productBadge", "test");
    });
    socket.on("newAppUserCreated", function (data) {
      SocketResponseState("newAppUserCreatedSocket", data);
    });

    socket.on("newOrderCreated", function (data) {
      SocketResponseState("newOrderCreated", { Record: data });
      SocketResponseState("orderBadge", "test");
    });
    socket.on("updateOrderStatus", function (data) {
      SocketResponseState("updateOrderStatus", { Record: data });
    });
    socket.on("newActivityCreated", function (data) {
      SocketResponseState("newActivityCreated", data);
    });
    socket.on("updateProductStatus", function (data) {
      SocketResponseState("updateProductStatus", { Record: data });
    });
    socket.on("editProduct", function (data) {
      SocketResponseState("editProduct", { Record: data });
    });

    function onConnect(userId) {
      return new Promise((resolve, reject) => {
        socket.emit("connect", { id: userId });
        resolve();
      });
    }
    function socketDisconnet() {
      return new Promise((resolve, reject) => {
        socket.emit("disconnect", {});
        resolve();
      });
    }
    return {
      onConnect,
      socketDisconnet,
    };
  }
  return {
    getInstance: function (userId) {
      if (!instance) {
        instance = createInstance(userId);
      }
      return instance;
    },
  };
})();
module.exports = {
  Socket: Socket,
};
