import React, { useState, useEffect } from 'react'
import FormModal from 'components/Modal/FormModal'
import { Form, Input,  Col, Select, InputNumber } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { DataGetAction } from 'redux/actions/http';
import Uploader from 'components/Uploader'
const AddOfferForm = ({ initialRecord }) => {



    const [fileList, setFileList] = useState([]);

    const teamProps = useSelector(state => state.productR)
    const offersProps = useSelector(state => state.offerR)
    const { Product } = teamProps;
    const { ShowModal, addingOffer, initialValues } = offersProps


    const dispatch = useDispatch();
    const fetchCategoryList = () => {
        dispatch(DataGetAction('productList', { query: "status", status: "approved" }, 'fetchingProductList'));
        if(initialValues){
            setFileList(initialValues.offerImage)
        }
        else{
            setFileList([])
        }
    }
    useEffect(fetchCategoryList, [initialValues])





    return (
        <div>
            <FormModal
                modalTitle={!initialValues || initialValues === "adding" ? "Add Offer" : "Update Offer"}
                basicButtonTitle="Add Offer"
                submitButtonText={!initialValues || initialValues === "adding" ? "Add" : "Update"}
                cancelButtonText="Cancel"
                apiMethod="POST"
                apiName="addOffer"
                editApiMethod="PUT"
                editApiName="editOffer"
                apiLoaderName="addingOffer"
                confirmLoading={addingOffer}
                ModalType="AddOffer_Modal"
                visible={ShowModal}
                initialValues={initialValues}
                initialRecord={initialRecord}
                extraFieldName="offerImage"
                extraFieldValue={fileList}
            >
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label="Offer Name"
                        name="offerName"
                        rules={[{ required: true, message: 'Please input sku!' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label="Products"
                        name="products"
                        rules={[{ required: true, message: 'Please input your Product Name!' }]}
                    >
                        <Select mode="multiple" allowClear>
                            {Product && Product.map(Item => <Select.Option value={Item._id}>{Item.productName}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        label="Offer percentage"
                        name="pricePercentage"
                        rules={[{ required: true, message: 'Please choose your parent category!' }]}
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                        name="offerImage"
                        label="Offer Image"
                        rules={[{ required: false, message: "Please enter document" }]}
                    >
                        <Uploader setFileList={setFileList} fileList={fileList} multiple={false} />
                    </Form.Item>
                </Col>
            </FormModal>
        </div>
    )
}

export default AddOfferForm
