import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import { Form, Input, Row, Col, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import GoogleMap from "./GoogleMap";
const InviteUser = ({ initialRecord }) => {
  const iuProps = useSelector((state) => state.manageUser);
  const teamProps = useSelector((state) => state.categoryR);
  const dispatch = useDispatch();
  const { ShowModal, invitingUser, initialValues } = iuProps;
  const [addressObj, setAddressObj] = useState(null);
  const { Category } = teamProps;
  const fetchCategoryList = () => {
    dispatch(
      DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList")
    );

    if (initialValues && initialValues.completeAddress) {
      setAddressObj(initialValues.completeAddress);
    }
    if (!ShowModal) {
      setAddressObj(null);
    }
  };
  useEffect(fetchCategoryList, [ShowModal]);

  return (
    <div>
      <FormModal
        modalTitle={
          !initialValues || initialValues === "adding" ? (
            <span className="gx-font-sans-bold">Add Vendor</span>
          ) : (
            <span className="gx-font-sans-bold">Update Vendor</span>
          )
        }
        basicButtonTitle="Add Vendor"
        isShowButton={false}
        submitButtonText={
          !initialValues || initialValues === "adding" ? "Add" : "Update"
        }
        cancelButtonText="Cancel"
        apiMethod="POST"
        apiName="addVendor"
        editApiMethod="PUT"
        editApiName="editVendor"
        apiLoaderName="addingUser"
        confirmLoading={invitingUser}
        ModalType="InviteUser_Modal"
        visible={ShowModal}
        initialValues={initialValues}
        initialRecord={initialRecord}
        extraFieldName={"completeAddress"}
        extraFieldValue={addressObj}
        address={addressObj && addressObj.address}
      >
        <Row className="gx-flex-row gx-px-0 gx-mx-0">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input your First Name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input your Last Name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              className="lower-case-email"
              rules={[
                { required: true, message: "Please input your email!" },
                { type: "email" },
              ]}
            >
              <Input disabled={initialValues ? true : false} />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Store Name"
              name="storeName"
              rules={[
                { required: true, message: "Please input your store name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              label="Store Code"
              name="storeShortCode"
              rules={[
                {
                  required: true,
                  message: "Please input your stores short code!",
                },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9_.-].{0,5}$/),
                  message: "Only six digit or alphabets are allowed",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label="Store Category"
              name="storeCategory"
              rules={[
                { required: true, message: "Please choose your category!" },
              ]}
            >
              <Select>
                {Category &&
                  Category.map((Item) => (
                    <Select.Option value={Item._id}>{Item.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <GoogleMap
          setAddressObj={setAddressObj}
          ShowModal={ShowModal}
          addressObj={initialValues ? addressObj : null}
        />
      </FormModal>
    </div>
  );
};

export default InviteUser;
