import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartCard from "../../../../components/ChartCard";
import { DataGetAction } from "redux/actions/http";

const UserChartComponent = () => {
  const dispatch = useDispatch();
  const userProps = useSelector((state) => state.usersR);
  const { cardsData, userStatusSuccess } = userProps;
  const fetchCardData = () => {
    dispatch(DataGetAction("getUserCards"));
  };
  useEffect(fetchCardData, []);
  if (userStatusSuccess) {
    fetchCardData();
    dispatch({ type: "ResetState" });
  }
  const Data = [
    { name: "Page A", price: 200 },
    { name: "Page B", price: 1200 },
    { name: "Page C", price: 600 },
    { name: "Page D", price: 1600 },
    { name: "Page D", price: 1000 },
    { name: "Page H", price: 2260 },
    { name: "Page K", price: 800 },
  ];

  return (
    <Row>
      <Col xl={8} lg={8} md={12} sm={12} xs={24}>
        <ChartCard
          count={cardsData && cardsData.totalUser}
          desc="Total Uers"
          Data={Data}
          bgColor={"#F3ECF9"}
          borderColor={"#C39FDF"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={12} xs={24}>
        <ChartCard
          count={cardsData && cardsData.sevenDays}
          desc=" Last 7 Days Active Users"
          Data={Data}
          bgColor={"#E3F7D7"}
          borderColor={"#B8E986"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={12} xs={24}>
        <ChartCard
          count={cardsData && cardsData.totalBlock}
          desc="Blocked Users"
          Data={Data}
          bgColor={"#FCEAF4"}
          borderColor={"#F8C8E3"}
        />
      </Col>
    </Row>
  );
};

export default UserChartComponent;
