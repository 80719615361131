import React, { useEffect } from "react";
import { Button, Card, Col, Row, Select } from "antd";
import RecentActivities from "components/ActivitiesList";
import ChartCard from "components/ChartCard";
import TopVendors from "./components/TopVendors";
import MonthlyAnalysis from "components/AreaChart";
import TotalRevenue from "./components/TotalRevenue";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import TinyBarChart from "./components/TotalUsersBarChart";
import SimpleLineChart from "./components/SimpleLineChart";
import SpendingAnalysisLineChart from "./components/SpendingAnalysisChart";
const Index = ({ dashboardData, topPerformingVendors }) => {
  const dispatch = useDispatch();
  const adminProps = useSelector((state) => state.adminDashboard);
  const {
    monthlyAnalytic,
    userStatistic,
    ActiveUserStatistic,
    SpendingAnalysis,
  } = adminProps;
  const fetchGeneralAnalytics = () => {
    dispatch(
      DataGetAction(
        "getMonthlyAnalytic",
        "",
        "gettingMonthlyAnalysis",
        "getSpendingAnalysisForAllCustomers"
      )
    );
  };
  useEffect(fetchGeneralAnalytics, []);
  const Data = [
    { name: "Page A", price: 200 },
    { name: "Page B", price: 1200 },
    { name: "Page C", price: 600 },
    { name: "Page D", price: 1600 },
    { name: "Page D", price: 1000 },
    { name: "Page H", price: 2260 },
    { name: "Page K", price: 800 },
  ];

  const handleChange = (e) => {
    let value = "";
    if (e) {
      value = e;
    } else {
      value = "monthly";
    }
    dispatch(DataGetAction("getStatisticsForNoOfSignup", { value: value }));
  };
  useEffect(handleChange, []);
  const handleChangeActiveUser = (e) => {
    let value = "";
    if (e) {
      value = e;
    } else {
      value = "weekly";
    }
    dispatch(DataGetAction("getUserDailyActivityGraph", { value: value }));
  };
  useEffect(handleChangeActiveUser, []);
  const FetchYearlySpending = () => {
    dispatch(
      DataGetAction("getSpendingAnalysisForAllCustomers", { query: "all" })
    );
  };
  useEffect(FetchYearlySpending, []);

  const refreshApi = () => {
    dispatch(DataGetAction("getAdminDashboardOverView"));
    FetchYearlySpending();
    handleChangeActiveUser();
    handleChange();
    fetchGeneralAnalytics();
  };

  return (
    <div className="gx-h-100">
      <div className="gx-d-flex gx-justify-content-end gx-p-1">
        <Button
          type="primary"
          className="gx-d-flex gx-align-items-center"
          onClick={() => refreshApi()}
          style={{ width: "80px" }}
        >
          Refresh
        </Button>
      </div>
      <Row>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <ChartCard
            count={(dashboardData && dashboardData.noOfVendor) || 0}
            desc="No of Vendors"
            Data={Data}
            bgColor={"#FCEAF4"}
            borderColor={"#F8C8E3"}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <ChartCard
            count={(dashboardData && dashboardData.appUsers) || 0}
            desc="No of App Users"
            Data={Data}
            bgColor={"#E3F7D7"}
            borderColor={"#B8E986"}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <ChartCard
            count={(dashboardData && dashboardData.orderActive) || 0}
            desc="No of Active Orders"
            Data={Data}
            bgColor={"#F3ECF9"}
            borderColor={"#C39FDF"}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
          <ChartCard
            count={(dashboardData && dashboardData.orderCompleted) || 0}
            desc="No of Complete Orders"
            Data={Data}
            bgColor={"#E5EEF5"}
            borderColor={"#59A6E5"}
          />
        </Col>
      </Row>

      <Row className="">
        <Col xl={8} lg={8} md={12} sm={12} xs={24}>
          <ChartCard
            count={(dashboardData && dashboardData.totalTodayRevenue) || 0}
            desc="Total Revenue For Today"
            Data={Data}
            bgColor={"#F3ECF9"}
            borderColor={"#C39FDF"}
          />
          <ChartCard
            count={(dashboardData && dashboardData.orderCompleted) || 0}
            desc="Total Amount For Today's Orders"
            Data={Data}
            bgColor={"#E5EEF5"}
            borderColor={"#59A6E5"}
          />
        </Col>

        <Col xl={9} lg={9} md={12} sm={24} xs={24}>
          <TotalRevenue
            totalRevenue={dashboardData && dashboardData.totalSale}
          />
        </Col>
        <Col xl={7} lg={7} md={24} sm={24} xs={24}>
          <RecentActivities showImage={false} />
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">
                Daily User Activity
              </h4>
            }
            extra={
              <Select
                defaultValue="weekly"
                style={{
                  width: 120,
                }}
                onChange={handleChangeActiveUser}
                options={[
                  {
                    value: "weekly",
                    label: "Weekly",
                  },
                  {
                    value: "monthly",
                    label: "monthly",
                  },
                  {
                    value: "yearly",
                    label: "Yearly",
                  },
                ]}
              />
            }
          >
            <SimpleLineChart data={ActiveUserStatistic} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={8} lg={17} md={12} sm={24} xs={24}>
          <TopVendors topPerformingVendors={topPerformingVendors} />
        </Col>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <MonthlyAnalysis
            data={monthlyAnalytic}
            width={"100%"}
            height={230}
            axisX={"name"}
            axisY={"Total Sale"}
            title={"Monthly Analysis"}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">
                User Analysis
              </h4>
            }
            extra={
              <Select
                defaultValue="monthly"
                style={{
                  width: 120,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "yearly",
                    label: "Yearly",
                  },
                  {
                    value: "monthly",
                    label: "Montly",
                  },
                ]}
              />
            }
          >
            <TinyBarChart data={userStatistic} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">
                Spending Analysis
              </h4>
            }
          >
            <SpendingAnalysisLineChart data={SpendingAnalysis} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
