import React, { useEffect } from "react";
import { Form, Input, Button, Spin, message, Select } from "antd";
import swal from "sweetalert";
import { DataRequestAction, DataGetAction } from "redux/actions/http";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  UserOutlined,
  MailOutlined,
  LockOutlined,
  BankOutlined,
} from "@ant-design/icons";

const Index = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const teamProps = useSelector((state) => state.categoryR);

  const {
    RegisteringLoader,
    registerUserSuccess,
    registerUserError,
    textMessage,
  } = authProps;

  const OpenSignInModal = () => {
    history.push("/login");
  };

  const fetchCategoryList = () => {
    dispatch(
      DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList")
    );
  };
  useEffect(fetchCategoryList, []);

  const { Category } = teamProps;

  if (registerUserSuccess) {
    swal({
      icon: "success",
      title: "Account Request",
      text: "You have registration request is send to admin for Activation. you get the email once account is active. Thanks",
      className: "userDetailAlert",
    }).then(() => {
      history.push("/login");
    });

    dispatch({
      type: "update_auth",
    });
  }

  if (registerUserError) {
    dispatch({
      type: "update_auth",
    });
    message.error(textMessage);
  }

  const onFinish = (values) => {
    dispatch(
      DataRequestAction("POST", "registerUser", values, "", "registeringUser")
    );
  };

  return (
    <div className="SignIn gx-p-5">
      <div className="welcomeMessage gx-text-center gx-mb-5">
        <h2 className="gx-font-weight-bold gx-mb-0">Getting started</h2>
        <p>Register your restaurant or hospital</p>
      </div>

      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstErrorZ
        initialValues={{ layout: "restaurant" }}
      >
        <Form.Item
          name={"firstName"}
          className="gx-ml-0 gx-mr-0"
          rules={[
            {
              required: true,
              message: "Please input your Name",
            },
          ]}
        >
          <Input
            placeholder="First Name"
            prefix={
              <UserOutlined
                style={{
                  marginRight: "5px",
                }}
              />
            }
          />
        </Form.Item>
        <Form.Item
          name={"lastName"}
          className="gx-ml-0 gx-mr-0"
          rules={[
            {
              required: true,
              message: "Please input your Name",
            },
          ]}
        >
          <Input
            placeholder="Last Name"
            prefix={
              <UserOutlined
                style={{
                  marginRight: "5px",
                }}
              />
            }
          />
        </Form.Item>
        <Form.Item
          name={"storeName"}
          className="gx-ml-0 gx-mr-0"
          rules={[
            {
              required: true,
              message: "Please input your Store Name",
            },
          ]}
        >
          <Input
            placeholder="Store Name"
            prefix={
              <BankOutlined
                style={{
                  marginRight: "5px",
                }}
              />
            }
          />
        </Form.Item>
        <Form.Item
          className="gx-ml-0 gx-mr-0"
          name="storeShortCode"
          rules={[
            { required: true, message: "Please input your stores short code!" },
            {
              pattern: new RegExp(/^[a-zA-Z0-9_.-].{0,5}$/),
              message: "Only six digit or alphabets are allowed",
            },
          ]}
        >
          <Input placeholder="Store Code" />
        </Form.Item>
        <Form.Item
          className="gx-ml-0 gx-mr-0"
          name="storeCategory"
          rules={[
            { required: true, message: "Please choose your parent category!" },
          ]}
        >
          <Select placeholder="Store Category">
            {Category &&
              Category.map((Item) => (
                <Select.Option value={Item._id}>{Item.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={"email"}
          className="gx-ml-0 gx-mr-0"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input
            placeholder="Email"
            prefix={
              <MailOutlined
                style={{
                  marginRight: "5px",
                }}
              />
            }
          />
        </Form.Item>

        <Form.Item
          name="password"
          className="gx-ml-0 gx-mr-0"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="Password"
            prefix={
              <LockOutlined
                style={{
                  marginRight: "5px",
                }}
              />
            }
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          className="gx-ml-0 gx-mr-0"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            prefix={
              <LockOutlined
                style={{
                  marginRight: "5px",
                }}
              />
            }
          />
        </Form.Item>

        <Form.Item className="gx-ml-0 gx-mr-0">
          {!RegisteringLoader ? (
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button gx-mt-4 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center"
              style={{ lineHeight: "0px" }}
            >
              Register
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="button"
              className="login-form-button gx-mt-4 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center"
              style={{ lineHeight: "0px" }}
            >
              <Spin size="small" />
            </Button>
          )}
        </Form.Item>
        <p className="gx-text-center">
          {" "}
          Already have an account?{" "}
          <span
            className="gx-pointer gx-link"
            onClick={() => OpenSignInModal()}
          >
            Login
          </span>
        </p>
      </Form>
    </div>
  );
};

export default Index;
