const INIT_STATE = {
  token: localStorage.getItem("token"),
  initURL: "",
  authUser: JSON.parse(localStorage.getItem("userInfo")),
  loginStart: false,
  success: false,
  error: false,
  message: "",
  otpSuccess: false,
  otpLoader: false,
  ShowSetNewLoader: false,
  ShowForgotLoader: false,
  userInfo: null,
  userRegisterSuccessfully: false,
  userRegisterFail: false,
  savingDetail: false,
  savingLoginDetail: false,
  loginDetailChangeSuccessfully: false,
  loginDetailChangeFail: false,
  loginFail: false,
  loginFailMessage: "",
  loginSuccess: false,
  logoutSuccess: false,
  ShowRestLoader: false,
  TokenSaveSuccess: false,

  RegisteringLoader: false,
  registerUserSuccess: false,
  registerUserError: false,
  textMessage: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "registeringUser_START": {
      return { ...state, RegisteringLoader: true };
    }

    case "registerUser_SUCCESS": {
      return {
        ...state,
        registerUserSuccess: true,
        RegisteringLoader: false,
        registerUserError: false,
      };
    }

    case "registerUser_FAILURE": {
      console.log("========= message =======", action);
      return {
        ...state,
        registerUserSuccess: false,
        RegisteringLoader: false,
        registerUserError: true,
        textMessage: action.payload,
      };
    }

    case "logout_success": {
      localStorage.clear();
      return {
        ...state,
        logoutSuccess: true,
        initURL: "/",
        token: null,
        authUser: null,
      };
    }

    case "saveEvent": {
      console.log("==============", action);
      localStorage.setItem("userInfo", JSON.stringify(action.userInfo));
      return {
        ...state,
        token: action.token,
        authUser: action.userInfo,
        TokenSaveSuccess: true,
      };
    }

    case "reset_START": {
      return { ...state, ShowRestLoader: true };
    }
    case "reset_STOP": {
      return { ...state, ShowRestLoader: false };
    }

    case "resetPassword_SUCCESS": {
      return {
        ...state,
        ShowRestLoader: false,
        error: false,
        success: true,
        message: "You are Successfully Reset Password.",
      };
    }
    case "resetPassword_FAILURE": {
      return {
        ...state,
        ShowRestLoader: false,
        error: true,
        success: false,
        message: action.payload,
      };
    }

    case "login_SUCCESS": {
      localStorage.setItem("userInfo", JSON.stringify(action.payload.user));
      localStorage.setItem("token", action.payload.user.token);

      return {
        ...state,
        loginFailMessage: "",
        loginSuccess: true,
        token: action.payload.user.token,
        authUser: action.payload.user,
      };
    }
    case "login_FAILURE": {
      console.log("============ Login Fail========", action);
      return { ...state, loginFail: true, loginFailMessage: action.payload };
    }
    case "changingLoginDetail_START": {
      return { ...state, savingLoginDetail: true };
    }
    case "setNewPassword_SUCCESS": {
      return {
        ...state,
        savingLoginDetail: false,
        loginDetailChangeSuccessfully: true,
        loginDetailChangeFail: false,
      };
    }
    case "setNewPassword_FAILURE": {
      return {
        ...state,
        savingLoginDetail: false,
        loginDetailChangeSuccessfully: false,
        loginDetailChangeFail: true,
      };
    }

    case "savingUserDetail_START": {
      return { ...state, savingDetail: true };
    }
    case "signUp_SUCCESS": {
      return { ...state, savingDetail: false, userRegisterSuccessfully: true };
    }
    case "signUp_FAILURE": {
      return { ...state, savingDetail: false, userRegisterFail: true };
    }

    case "forgetPassword_SUCCESS": {
      return {
        ...state,
        ShowForgotLoader: false,
        error: false,
        success: true,
        message: "You are Successfully Reset Password.",
      };
    }
    case "forgetPassword_FAILURE": {
      return {
        ...state,
        ShowForgotLoader: false,
        error: true,
        success: false,
        message: action.payload,
      };
    }

    case "forgot_START": {
      return { ...state, ShowForgotLoader: true };
    }
    case "forgot_STOP": {
      return { ...state, ShowForgotLoader: false };
    }

    case "setNew_START": {
      return { ...state, ShowSetNewLoader: true };
    }
    case "setNew_STOP": {
      return { ...state, ShowSetNewLoader: false };
    }

    case "sendOTP_START": {
      return { ...state, otpLoader: true };
    }
    case "sendOTP_STOP": {
      return { ...state, otpLoader: false };
    }
    case "verifyCode_SUCCESS": {
      return { ...state, otpSuccess: true, userInfo: action.payload.Record };
    }
    case "signUpByNumber_SUCCESS": {
      return { ...state, message: "Success", success: true };
    }
    case "signUpByNumber_FAILURE": {
      return { ...state, message: "fail", error: true };
    }
    case "INIT_URL": {
      return { ...state, initURL: action.payload };
    }
    case "loginStart_START": {
      return { ...state, loginStart: true };
    }
    case "loginStart_STOP": {
      return { ...state, loginStart: false };
    }

    case "update_auth": {
      return {
        ...state,
        success: false,
        error: false,
        message: "",
        otpSuccess: false,
        otpLoader: false,
        ShowSetNewLoader: false,
        ShowForgotLoader: false,
        userRegisterSuccessfully: false,
        userRegisterFail: false,
        savingDetail: false,
        savingLoginDetail: false,
        loginDetailChangeSuccessfully: false,
        loginDetailChangeFail: false,
        loginFail: false,
        loginSuccess: false,
        logoutSuccess: false,
        loginStart: false,
        ShowRestLoader: false,
        TokenSaveSuccess: false,
        RegisteringLoader: false,
        registerUserSuccess: false,
        registerUserError: false,
      };
    }
    default:
      return state;
  }
};
