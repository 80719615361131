import React from "react";
import FormModal from "components/Modal/FormModal";
import { Form, Input, Row, Col } from "antd";
import { useSelector } from "react-redux";

const Index = () => {
  const iuProps = useSelector((state) => state.usersR);
  const { ShowModal, invitingUser, initialValues } = iuProps;
  return (
    <div>
      <FormModal
        modalTitle={<span className="gx-font-sans-bold">Reset password</span>}
        basicButtonTitle="Reset Password"
        submitButtonText="Reset"
        cancelButtonText="Cancel"
        editApiMethod="PUT"
        editApiName="resetUserPassword"
        apiLoaderName="invitingUser"
        confirmLoading={invitingUser}
        ModalType="ResetPassword_Modal"
        visible={ShowModal}
        buttonStyleClass="gx-buttonStyleClass"
        isShowButton={false}
        initialValues={initialValues}
      >
        <Row className="gx-flex-row gx-px-0 gx-mx-0">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Both passwords must match!");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      </FormModal>
    </div>
  );
};

export default Index;
