import React from "react";
import FormModal from "components/Modal/FormModal";
import { Form, Input, Row, Col } from "antd";
import { useSelector } from "react-redux";
const InviteUser = ({ initialRecord }) => {
  const iuProps = useSelector((state) => state.categoryR);

  const { ShowModal, addingCategory, initialValues } = iuProps;

  return (
    <div>
      <FormModal
        modalTitle={
          !initialValues || initialValues === "adding" ? (
            <span className="gx-font-sans-bold">Add Category</span>
          ) : (
            <span className="gx-font-sans-bold">Update Category</span>
          )
        }
        basicButtonTitle="Add Category"
        submitButtonText={
          !initialValues || initialValues === "adding" ? "Add" : "Update"
        }
        cancelButtonText="Cancel"
        apiMethod="POST"
        apiName="addCategory"
        editApiMethod="PUT"
        editApiName="editCategory"
        apiLoaderName="addingCategory"
        confirmLoading={addingCategory}
        ModalType="AddCategory_Modal"
        visible={ShowModal}
        initialValues={initialValues}
        initialRecord={initialRecord}
      >
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Category Name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </FormModal>
    </div>
  );
};

export default InviteUser;
