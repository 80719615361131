import React from 'react'
import { useDispatch } from 'react-redux'

const ProductListModal = ({ productList }) => {
    const dispatch = useDispatch()
    return (
        <>
            <span style={{
                color: '#0d7dd9',
                cursor: 'pointer'
            }}
                onClick={() => {
                    dispatch({
                        type: `Products_Modal`,
                        payload : productList
                    })
                }}>View</span>


    
            
        </>
    )
}


export default ProductListModal
