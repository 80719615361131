import React from "react";
import { Layout } from "antd";

import SideBar from "./Sidebar";
import Topbar from "./Topbar";

const { Content, Footer } = Layout;

const Index = (props) => {
  return (
    <Layout className="gx-app-layout">
      <SideBar />
      <Layout>
        <Topbar />
        <Content
          className="gx-layout-content"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            height: "100%",
          }}
        >
          {props.children}
        </Content>
        <Footer></Footer>
      </Layout>
    </Layout>
  );
};

export default Index;
