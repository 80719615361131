const INIT_STATE = {
  offers: [],
  addingOffer: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
  productsModelVisible: false,
  productsModalList: [],
  productsModalVisible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "updateOfferStatus_SUCCESS": {
      const rec = getOfferArray(state.offers, action.payload.Record);
      return {
        ...state,
        offers: [...rec],
        textMessage: "offer status updated successfully",
        success: true,
      };
    }

    case "deleteOffer_SUCCESS": {
      const offerArray = removeDeletedoffersFromState(
        state.offers,
        action.payload.Record
      );
      return {
        ...state,
        offers: [...offerArray],
        addingOffers: false,
        success: true,
        textMessage: "offer deleted successfully",
      };
    }

    case "editOffer_SUCCESS": {
      const offersArr = getOfferArray(state.offers, action.payload.Record);
      return {
        ...state,
        initialValues: null,
        offers: [...offersArr],
        addingOffers: false,
        success: true,
        textMessage: "offer updated successfully",
      };
    }

    case "offerList_SUCCESS": {
      return { ...state, offers: action.payload.Record };
    }

    case "addingOffers_START": {
      return { ...state, addingOffers: true, initialValues: null };
    }

    case "addOffer_SUCCESS": {
      return {
        ...state,
        offers: [action.payload.Record, ...state.offers],
        addingOffers: false,
        success: true,
        initialValues: null,
        textMessage: "offer added successfully",
      };
    }
    case "addOffer_FAILURE": {
      return {
        ...state,
        addingOffers: false,
        success: false,
        error: true,
        textMessage: "some error occur while adding offer. kindly try again",
      };
    }
    case "AddOffer_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }
    case "Products_Modal": {
      console.log("Products_Modal", action.payload);
      return {
        ...state,
        productsModelVisible: !state.productsModelVisible,
        productsModalList: action.payload,
      };
    }
    case "resetOfferState": {
      return {
        ...state,
        addingOffers: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getOfferArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedoffersFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
