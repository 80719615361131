import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import Widget from "components/Widget";
import { Row, Col, Avatar, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import UpdateSettings from "./components/UpdateSettings";
import { UserOutlined } from "@ant-design/icons";
import CurrencySetting from "./components/CurrencySetting";
const Index = () => {
  const dispatch = useDispatch();
  const getCurrencyApi = () => {
    dispatch(DataGetAction("getCurrency", "", ""));
  };
  const fetchSettingsValues = () => {
    dispatch(DataGetAction("getSetting", { query: "all" }, "gettingSetting"));
    if (authUser && authUser.role === "superAdmin") {
      getCurrencyApi();
    }
  };
  useEffect(fetchSettingsValues, []);

  const { currencyValues, currencyMessage, currencyError, currencySuccess } =
    useSelector((state) => state.currencyR);
  const settingProps = useSelector((state) => state.SettingR);
  const { settingValues } = settingProps;
  const { authUser } = useSelector((state) => state.auth);
  const { firstName, lastName, email, phoneNumber, profileImageUrl } =
    settingValues;
  if (currencySuccess) {
    getCurrencyApi();
    message.success(currencyMessage);
    dispatch({ type: "RESET_CURRENCY" });
  }
  if (currencyError) {
    message.error(currencyMessage);
    dispatch({ type: "RESET_CURRENCY" });
  }
  return (
    <Layout>
      <Title title="Settings" />
      <Row className="gx-mt-3">
        <Col xl={12} lg={12} md={12} sm={12} xs={24}>
          <Widget extra={<UpdateSettings />}>
            <div className="gx-d-flex gx-align-items-center gx-mb-3">
              <div>
                {profileImageUrl && (
                  <img
                    src={profileImageUrl}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                )}
                {!profileImageUrl && (
                  <Avatar size={50} icon={<UserOutlined />} />
                )}
              </div>
              <div>
                <p className="gx-mb-0 gx-ml-2">{firstName + " " + lastName}</p>
              </div>
            </div>
            <div className="gx-d-flex gx-align-items-center gx-mb-3">
              <span
                className="icon icon-email gx-mr-3 gx-fs-xxxl "
                style={{ color: "#0D7DD9" }}
              ></span>
              <div>
                <div style={{ color: "#989898" }}>Email</div>
                <div className="gx-blue-font gx-font-sans-bold">{email}</div>
              </div>
            </div>

            <div className="gx-d-flex gx-align-items-center gx-mb-3">
              <span
                className="icon icon-phone gx-mr-3 gx-fs-xxxl "
                style={{ color: "#0D7DD9" }}
              ></span>
              <div>
                <div style={{ color: "#989898" }}>Phone</div>
                <div className="gx-blue-font gx-font-sans-bold">
                  {phoneNumber}
                </div>
              </div>
            </div>
          </Widget>
        </Col>
        {authUser && authUser.role === "superAdmin" && (
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Widget>
              <div className="gx-d-flex gx-align-items-center gx-justify-content-between gx-w-100 gx-mb-4">
                <h4 className="gx-font-sans-bold">Currency Setting</h4>
                <CurrencySetting />
              </div>
              <div className="gx-d-flex gx-align-items-center gx-mb-3">
                <div>
                  <div style={{ color: "#989898" }}>Currency Name</div>
                  <div className="gx-blue-font gx-font-sans-bold">
                    {currencyValues && currencyValues.currencyName
                      ? currencyValues.currencyName
                      : "---"}
                  </div>
                </div>
              </div>

              <div className="gx-d-flex gx-align-items-center gx-mb-3">
                <div>
                  <div style={{ color: "#989898" }}>Stripe Currency Type</div>
                  <div className="gx-blue-font gx-font-sans-bold">
                    {currencyValues && currencyValues.stripeCurrencyType
                      ? currencyValues.stripeCurrencyType
                      : "---"}
                  </div>
                </div>
              </div>
              {authUser && authUser.role === "superAdmin" && (
                <div className="gx-d-flex gx-align-items-center gx-mb-3">
                  <div>
                    <div style={{ color: "#989898" }}>Currency Symbol</div>
                    <div className="gx-blue-font gx-font-sans-bold">
                      {currencyValues && currencyValues.currencySymbol
                        ? currencyValues.currencySymbol
                        : "---"}
                    </div>
                  </div>
                </div>
              )}
            </Widget>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

export default Index;
