const INIT_STATE = {
  Order: [],
  addingOrder: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
  Customer: [],
  Products: [],
  Store: [],
  showDeliveredModal: false,
  selectedOrder: null,
  showOTPSpinner: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "showDeliveredModal": {
      return {
        ...state,
        showDeliveredModal: !state.showDeliveredModal,
        selectedOrder: action.payload,
      };
    }
    case "getCustomerList_SUCCESS": {
      return { ...state, Customer: action.payload.Record };
    }
    case "getAllVendorsOrders_SUCCESS": {
      return { ...state, AllVenderOrders: action.payload.Record };
    }

    case "getProductList_SUCCESS": {
      return { ...state, Products: action.payload.Record };
    }
    case "getStoreList_SUCCESS": {
      return { ...state, Store: action.payload.Record };
    }
    case "deleteOrder_SUCCESS": {
      const orderArray = removeDeletedOrderFromState(
        state.Order,
        action.payload.Record
      );
      return {
        ...state,
        Order: [...orderArray],
        addingOrder: false,
        success: true,
        textMessage: "order deleted successfully",
      };
    }

    case "updatingOrderStatus_START": {
      return {
        ...state,
        showOTPSpinner: true,
      };
    }

    case "updateOrderStatus_SUCCESS": {
      const orderArr = getOrderArray(state.Order, action.payload.Record);
      return {
        ...state,
        Order: [...orderArr],
        addingOrder: false,
        success: true,
        showOTPSpinner: false,
        showDeliveredModal: false,
        textMessage: "order updated successfully",
      };
    }
    case "editOrder_SUCCESS": {
      const orderArr = getOrderArray(state.Order, action.payload.Record);
      return {
        ...state,
        Order: [...orderArr],
        addingOrder: false,
        success: true,
        textMessage: "order updated successfully",
      };
    }

    case "orderList_SUCCESS": {
      return { ...state, Order: action.payload.Record };
    }

    case "addingOrder_START": {
      return { ...state, addingOrder: true, initialValues: null };
    }
    case "addOrder_SUCCESS": {
      return {
        ...state,
        Order: [action.payload.Record, ...state.Order],
        addingOrder: false,
        success: true,
        textMessage: "order added successfully",
      };
    }
    case "newOrderCreated_SUCCESS": {
      const loginUser = JSON.parse(localStorage.getItem("userInfo"))._id;
      console.log(
        "====== new Order Created =====",
        action.payload.Record,
        state.Order,
        loginUser
      );
      if (loginUser === action.payload.Record.storeId) {
        let orderList = [action.payload.Record, ...state.Order];
        return {
          ...state,
          Order: [...orderList],
          addingOrder: false,
          success: true,
          textMessage: "order added successfully",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "addOrder_FAILURE": {
      return {
        ...state,
        addingOrder: false,
        success: false,
        error: true,
        textMessage: "some error occur while adding order. kindly try again",
      };
    }
    case "AddOrder_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }
    case "ResetPassword_Modal": {
      return {
        ...state,
        ShowModalPassword: !state.ShowModalPassword,
        initialValues: action.payload,
      };
    }

    case "resetOrderState": {
      return {
        ...state,
        addingOrder: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
        ShowModalPassword: false,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getOrderArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        if (newData.status === "ready") {
          Item.status = "ready";
          newArray.push(Item);
        }
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedOrderFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
