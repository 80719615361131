import React from "react";
import { Table } from "antd";

const index = ({ columns, data, scroll, styleName, pagination, size }) => {
  return (
    <Table
      className={`BasicTable gx-table-responsive ${styleName}`}
      columns={columns}
      dataSource={data}
      scroll={scroll}
      pagination={pagination}
      size={size}
    />
  );
};

export default index;
