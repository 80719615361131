import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import UserList from "components/Table/BasicTable";
import TableAction from "components/Table/Actions";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction, DataRequestAction } from "redux/actions/http";
import { message, DatePicker, Input } from "antd";
import CallFilters from "components/Filters/CallFilters";
const { RangePicker } = DatePicker;
const PendingVendor = () => {
  const dispatch = useDispatch();
  const teamProps = useSelector((state) => state.manageUser);

  const { success, error, textMessage } = teamProps;

  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction(
        "vendorList",
        { query: "status", status: "pending" },
        "fetchingVendorList"
      )
    );
  };
  useEffect(fetchTeamUsers, []);

  const renderStatus = (data) => {
    if (data.status === "pending") {
      return (
        <span
          style={{
            color: "#FFB800",
          }}
        >
          Pending
        </span>
      );
    }
  };

  const setUserStatus = (id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateStatus",
        { _id: id, status },
        "",
        "userStatsUpdating",
        false
      )
    );
  };

  const onChange = (dates, dateStrings) => {
    dispatch(
      DataGetAction(
        "inspectionHistory",
        {
          query: "search",
          key: "date",
          stateDate: dates[0],
          endDate: dates[1],
        },
        "gettingInspectionHistory"
      )
    );
  };

  const columns = [
    {
      title: "Name",
      render: (record) => <span>{record.name}</span>,
    },
    {
      title: "Email",
      render: (record) => <span>{record.email}</span>,
    },
    {
      title: "Store Name",
      render: (record) => <span>{record.storeName}</span>,
    },
    {
      title: "Category",
      render: (record) => <span>{record.storeCategory}</span>,
    },
    {
      title: "Status",
      render: (record) => {
        return renderStatus(record);
      },
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      width: 90,
    },
  ];

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetUserInvitationState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetUserInvitationState",
    });
  }

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.status === "pending" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "active")}
          >
            Approve
          </span>
        )}
        {record.status === "pending" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "reject")}
          >
            Delete
          </span>
        )}
      </div>
    );
  };

  const { Users } = teamProps;

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        <Title title="Vendor" />
        <div className="filters" style={{ marginRight: "12px" }}>
          <RangePicker onChange={onChange} className="gx-mr-3" />

          <CallFilters
            isShowingAll={true}
            apiName="userList"
            searchKey="status"
            options={[
              { status: "active", label: "Active" },
              { status: "pending", label: "Invited" },
              { status: "block", label: "Block" },
            ]}
            placeholder="Filter by Membership"
            ItemValue="status"
            label="label"
          />

          <Input.Search
            showSearch
            placeholder="Name"
            filterOption={false}
            onChange={(e) =>
              dispatch(
                DataGetAction("categoryList", { name: e.target.value }, "")
              )
            }
            style={{ width: "220px", marginLeft: "12px" }}
          />
        </div>
      </div>
      <UserList data={Users} columns={columns} />
    </Layout>
  );
};

export default PendingVendor;
