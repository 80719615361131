import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import OrderList from "components/Table/BasicTable";
import { DataGetAction } from "redux/actions/http";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Input } from "antd";
const Index = () => {
  const dispatch = useDispatch();
  const orderProps = useSelector((state) => state.orderR);
  const { AllVenderOrders } = orderProps;
  const { RangePicker } = DatePicker;
  const onChange = (dates, dateStrings) => {
    if (dates && dates.length) {
      dispatch(
        DataGetAction(
          "getAllVendorsOrders",
          {
            query: "search",
            key: "date",
            startDate: dates[0],
            endDate: dates[1],
          },
          "fetchingOrderList"
        )
      );
    }
  };

  const FetchAllVendorData = () => {
    dispatch(DataGetAction("getAllVendorsOrders", { query: "all" }));
  };
  useEffect(FetchAllVendorData, []);
  // const renderActions = (record) => {
  //   return (
  //     <div className="gx-d-flex gx-flex-column">
  //       {record.status === "active" && (
  //         <DeleteConfirm
  //           method="PUT"
  //           title="Are you sure you want to block this user"
  //           okText="Yes"
  //           cancelText="No"
  //           type="updateUserStatus"
  //           buttonName="Block"
  //           fontSize="14px"
  //           selectedItem={{ _id: record._id, status: "block" }}
  //         />
  //       )}
  //       {(record.status === "block" || record.status === "delete") && (
  //         <DeleteConfirm
  //           method="PUT"
  //           title="Are you sure you want to active this user"
  //           okText="Yes"
  //           cancelText="No"
  //           type="updateUserStatus"
  //           buttonName="Active"
  //           fontSize="14px"
  //           selectedItem={{ _id: record._id, status: "active" }}
  //         />
  //       )}
  //       <span
  //         style={{ cursor: "pointer" }}
  //         onClick={() => resetPassword(record)}
  //       >
  //         Reset Password
  //       </span>
  //     </div>
  //   );
  // };

  const columns = [
    {
      title: "Order Id",
      render: (record) => <span>{record.orderNo}</span>,
    },
    // {
    //   title: "Product Name",
    //   render: (record) => <span>{record.productInfo || ""}</span>,
    // },
    {
      title: "Qty",
      render: (record) => <span>{record.orderAmount || ""}</span>,
    },
    {
      title: "Customer Name",
      render: (record) => <span>{record.vendorName || ""}</span>,
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || ""}</span>,
    },
    {
      title: "Phone Number",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
    },
    {
      title: "Date & Time",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")}</span>
      ),
    },
    // {
    //   title: "Status",
    //   render: (record) => (
    //     <span className="gx-text-capitalize">
    //       {renderStatus(record.status) || "---"}
    //     </span>
    //   ),
    //   key: "status",
    // },
    // {
    //   title: "",
    //   render: (record) => <TableAction> {renderActions(record)}</TableAction>,
    //   width: "90px",
    // },
  ];

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between gx-p-2">
        <Title title="Orders" />
        <div className="gx-d-flex">
          <div className="filters" style={{ marginRight: "12px" }}>
            <RangePicker onChange={onChange} className="gx-mr-3" />
            <Input.Search
              showSearch
              placeholder="Search by name"
              filterOption={false}
              onChange={(e) =>
                dispatch(
                  DataGetAction("getAllVendorsOrders", { name: e.target.value }, "")
                )
              }
              style={{ width: "200px", marginLeft: "12px" }}
            />
          </div>
        </div>
      </div>
      <OrderList data={AllVenderOrders} columns={columns} />
    </Layout>
  );
};

export default Index;
