import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import Title from 'components/Title'
import StoreReportList from 'components/Table/BasicTable'
import TableAction from 'components/Table/Actions'
import DeleteConfirm from 'components/Modal/DeleteConfirm'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction, DataRequestAction } from 'redux/actions/http';
import { message, DatePicker } from 'antd'

const { RangePicker } = DatePicker;
const Index = () => {

    const dispatch = useDispatch();
    const teamProps = useSelector(state => state.manageUser)


    const { success, error, textMessage } = teamProps

    const fetchTeamUsers = () => {
        dispatch(DataGetAction('userList', { query: "all" }, 'fetchingUserList'));
    }
    useEffect(fetchTeamUsers, [])





    const resendInvite = (record) => {
        dispatch(DataRequestAction('PUT', 'resentInvitationEmail', record, '', 'resentEmail', false));
    }
    const setUserStatus = (userId, status) => {
        dispatch(DataRequestAction('PUT', 'updateStatus', { userId, status }, '', 'userStatsUpdating', false));
    }

    const onChange = (dates, dateStrings) => {
        dispatch(DataGetAction('userList', { query: "search", key: "date", stateDate: dates[0], endDate: dates[1] }, 'gettingUserList'));
        // console.log('From: ', dates[0], ', to: ', dates[1]);
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    }

    const columns = [
        {
            title: 'Name',
            render: record => <span>{record.firstName}</span>,
        },
        {
            title: 'Email',
            render: record => <span>{record.email}</span>,
        },
        {
            title: 'Phone',
            render: record => <span>{record.phoneNumber}</span>,
        },
        {
            title: 'Store name',
            render: record => <span>{record.storeName}</span>,
        },
        {
            title: 'Sales',
            render: record => <span>{record.sales}</span>,
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            width: 90
        },
    ];

    if (success) {
        message.success(textMessage)
        dispatch({
            type: "resetUserInvitationState"
        })
    }

    if (error) {
        message.error(textMessage)
        dispatch({
            type: "resetUserInvitationState"
        })
    }

    const editRecord = (record) => {
        dispatch({
            type: "InviteUser_Modal",
            payload: record
        })
    }





    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            {record.status === "active" && <span style={{ cursor: 'pointer' }} onClick={() => editRecord(record)}>Edit</span>}
            {record.status === "active" && <span style={{ cursor: 'pointer' }} onClick={() => setUserStatus(record._id, 'block')}>Block</span>}
            {record.status === "block" && <span style={{ cursor: 'pointer' }} onClick={() => setUserStatus(record._id, 'active')}>unBlock</span>}
            <DeleteConfirm
                type="deleteUser"
                method="DELETE"
                selectedItem={record}
                buttonName="Yes"
                title="Are you sure you want to delete this user?"
                content=""
                Icon={<span style={{ fontSize: '14px' }}>Delete</span>}
            />
            {record.status === "pending" && <span style={{ cursor: 'pointer' }} onClick={() => resendInvite(record)}>Resend Invitation</span>}
        </div>
    }

    const { Users } = teamProps;


    return (
        <Layout>
            <div className="gx-d-block gx-flex-row gx-justify-content-between">
                <Title title="Reports > Store" />
                <div className="gx-d-flex">
                <div className="filters" style={{ marginRight: "12px" }}>
                        <RangePicker onChange={onChange} className="gx-mr-3" />
                    </div>
                </div>

            </div>
            <StoreReportList data={Users} columns={columns} />

        </Layout>
    )
}

export default Index
