import React from 'react'
import { useDispatch } from 'react-redux'

const ImagesList = ({ imagesList }) => {
    const dispatch = useDispatch()
    return (
        <>
            <span style={{
                color: '#0d7dd9',
                cursor: 'pointer'
            }}
                onClick={() => {
                    dispatch({
                        type: `Images_Modal`,
                        payload : imagesList
                    })
                }}>View</span>
            
        </>
    )
}


export default ImagesList
