import React,{useEffect} from 'react';
import { Col, Row } from 'antd'
import RecentActivities from 'components/ActivitiesList'
import ChartCard from 'components/ChartCard'
import TopProducts from './components/TopProducts'
import MonthlyAnalysis from 'components/AreaChart'
import TotalSales from './components/TotalSales'

import RecentOrderReq from './components/RecentOrderReq'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction } from 'redux/actions/http'
const Index = ({dashboardData,topProducts}) => {


    const dispatch = useDispatch();
    const adminProps = useSelector(state => state.adminDashboard)
    const  {monthlyAnalytic } = adminProps
    const fetchGeneralAnalytics = () => {
        dispatch(DataGetAction('getMonthlyAnalytic', '', 'gettingMonthlyAnalysis'));
    }
    useEffect(fetchGeneralAnalytics, [])



    const Data = [
        { name: 'Page A', price: 200 },
        { name: 'Page B', price: 1200 },
        { name: 'Page C', price: 600 },
        { name: 'Page D', price: 1600 },
        { name: 'Page D', price: 1000 },
        { name: 'Page H', price: 2260 },
        { name: 'Page K', price: 800 },
    ];




    return (
        <div className="gx-h-100">
            <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                            <ChartCard count={dashboardData && dashboardData.totalProduct} desc="No of Products" Data={Data} bgColor={'#FCEAF4'} borderColor={'#F8C8E3'} />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                            <ChartCard count={dashboardData && dashboardData.appUsers} desc="No of App Users" Data={Data} bgColor={'#E3F7D7'} borderColor={'#B8E986'} />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                            <ChartCard count={dashboardData && dashboardData.orderActive} desc="No of Active Orders" Data={Data} bgColor={'#F3ECF9'} borderColor={'#C39FDF'} />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={24}>
                            <ChartCard count={dashboardData && dashboardData.orderCompleted} desc="No of Completed Orders" Data={Data} bgColor={'#E5EEF5'} borderColor={'#59A6E5'} />
                        </Col>
                    </Row>
                    <Row>

                        <Col xl={17} lg={17} md={24} sm={24} xs={24}>
                            <Row>
                                <Col xl={15} lg={15} md={12} sm={24} xs={24}>
                                    <TopProducts topProducts={topProducts} />
                                </Col>
                                <Col xl={9} lg={9} md={12} sm={24} xs={24}>
                                    <TotalSales totalSale = {dashboardData && dashboardData.totalSale} />
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <RecentOrderReq />

                                </Col>
                            </Row>
                            <Row>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                                    <MonthlyAnalysis data={monthlyAnalytic} width={'100%'} height={180} axisX={'name'} axisY={'Total Sale'} title={"Monthly Analysis"}   />
                                </Col>
                            </Row>


                        </Col>
                        <Col xl={7} lg={7} md={24} sm={24} xs={24}>
                            <RecentActivities  showImage={false} title={'Notifications'} />
                        </Col>

                    </Row>


                </Col>
            </Row>








        </div>
    )
}

export default Index
