import React from "react";
import { Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
const Index = ({ children }) => {
  return (
    <Popover placement="bottom" content={children} title="" trigger="focus">
      <button
        className="gx-pointer gx-d-flex"
        style={{
          fontSize: "14px",
          color: "#038FDE",
          background: "none",
          border: "none",
        }}
      >
        <MoreOutlined className="tableActions" />
      </button>
    </Popover>
  );
};

export default Index;
