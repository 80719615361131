const INIT_STATE = {
  SubCategory: [],
  addingSubCategory: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "deleteSubCategory_SUCCESS": {
      const subCategoryArray = removeDeletedSubCategoryFromState(
        state.SubCategory,
        action.payload.Record
      );
      return {
        ...state,
        SubCategory: [...subCategoryArray],
        addingSubCategory: false,
        success: true,
        textMessage: "subCategory deleted successfully",
      };
    }

    case "editSubCategory_SUCCESS": {
      const subCategoryArr = getSubCategoryArray(
        state.SubCategory,
        action.payload.Record
      );
      return {
        ...state,
        SubCategory: [...subCategoryArr],
        addingSubCategory: false,
        success: true,
        textMessage: "subCategory updated successfully",
      };
    }

    case "subCategoryList_SUCCESS": {
      return { ...state, SubCategory: action.payload.Record };
    }

    case "addingSubCategory_START": {
      return { ...state, addingSubCategory: true, initialValues: null };
    }
    case "addSubCategory_SUCCESS": {
      return {
        ...state,
        SubCategory: [action.payload.Record, ...state.SubCategory],
        addingSubCategory: false,
        success: true,
        textMessage: "subCategory added successfully",
      };
    }
    case "addSubCategory_FAILURE": {
      return {
        ...state,
        addingSubCategory: false,
        success: false,
        error: true,
        textMessage:
          "some error occur while adding subCategory. kindly try again",
      };
    }
    case "AddSubCategory_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }

    case "resetSubCategoryState": {
      return {
        ...state,
        addingSubCategory: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
        ShowModalPassword: false,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getSubCategoryArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedSubCategoryFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
