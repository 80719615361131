import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Auth from "./Auth";
import Common from "./Common";
import Settings from "./Settings";
import AdminDashboard from "./AdminDashboard";
import Category from "./category";
import Product from "./product";
import SubCategory from "./subCategory";
import Order from "./order";
import ManageUser from "./ManageUser";
import SettingR from "./SettingProfile";
import Report from "./Report";
import Dashboard from "./Dashboard";
import activities from "./Activities";
import Badge from "./Badge";
import offer from "./offer";
import Users from "./Users";
import currency from "./currency";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    activities: activities,
    settings: Settings,
    auth: Auth,
    commonR: Common,
    adminDashboard: AdminDashboard,
    manageUser: ManageUser,
    categoryR: Category,
    usersR: Users,
    subCategoryR: SubCategory,
    SettingR: SettingR,
    productR: Product,
    orderR: Order,
    reportR: Report,
    dashboard: Dashboard,
    badgeR: Badge,
    offerR: offer,
    currencyR: currency,
  });
