import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import ProductList from "components/Table/BasicTable";
import TableAction from "components/Table/Actions";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import AddProductForm from "./components/AddProductForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataGetAction, DataRequestAction } from "redux/actions/http";
import { message, DatePicker, Input, Carousel } from "antd";
import CallFilters from "components/Filters/CallFilters";
import ImagesList from "./components/ImagesList";
import SimpleModal from "components/Modal/SimpleModal";
import moment from "moment";
import EditProductForm from "./components/EditProductForm";
import BulkUploadModel from "./components/BulkUploadModel";
const { RangePicker } = DatePicker;
const Index = () => {
  const loginUser = JSON.parse(localStorage.getItem("userInfo")).role;
  const dispatch = useDispatch();
  const params = useParams();

  const { query } = params;
  const teamProps = useSelector((state) => state.productR);
  const categoryProps = useSelector((state) => state.categoryR);

  const {
    success,
    error,
    textMessage,
    imagesModelVisible,
    imagesList,
    initialValue,
    addressList,
    priceList,
    priceModelVisible,
    ShowModalEdit,
    initialValues,
  } = teamProps;
  const { Category } = categoryProps;
  const fetchTeamUsers = () => {
    let data = { query: "status", status: query };
    if (query === "all") {
      data = { query: "all" };
    }

    dispatch(DataGetAction("getAddress", "", "fetchingAddress"));
    dispatch(DataGetAction("productList", data, "fetchingProductList"));
    dispatch(
      DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList")
    );
    dispatch({
      type: "productBadge_SUCCESS",
    });
  };
  useEffect(fetchTeamUsers, [query]);

  const onChange = (dates, dateStrings) => {
    if (dates && dates.length) {
      dispatch(
        DataGetAction(
          "productList",
          {
            query: "search",
            key: "date",
            startDate: dates[0],
            endDate: dates[1],
          },
          "gettingProductList"
        )
      );
    }
  };
  let column = [];
  let adminColumn = [
    {
      title: "Product ID",
      render: (record) => <span>{record.productNo}</span>,
    },
    {
      title: "Product Name",
      render: (record) => <span>{record.productName}</span>,
    },
    {
      title: "Vendor Name",
      render: (record) => <span>{record.addedBy}</span>,
    },
    {
      title: "Vendor address",
      render: (record) => <span>{record.address}</span>,
    },
    {
      title: "Category",
      render: (record) => <span>{record.categoryName}</span>,
    },
    {
      title: "Est Time",
      render: (record) => <span>{record.estimatedTime}</span>,
    },
    {
      title: "Price",
      render: (record) =>
        record.productSize === "multiSize" ? (
          <span
            className="gx-link"
            onClick={() => {
              dispatch({
                type: "togglePriceModal",
                payload: record.priceList,
              });
            }}
          >
            see prices
          </span>
        ) : (
          <span>{record.price}</span>
        ),
    },
    {
      title: "Date & Time",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")} </span>
      ),
    },
    {
      title: "Images",
      render: (record) => <ImagesList imagesList={record.images} />,
    },
    {
      title: "Status",
      render: (record) => <span class="status">{record.status}</span>,
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
    },
  ];

  let vendorColumn = [
    {
      title: "Product ID",
      render: (record) => <span>{record.productNo}</span>,
    },
    {
      title: "Product Name",
      render: (record) => <span>{record.productName}</span>,
    },
    {
      title: "Category",
      render: (record) => <span>{record.categoryName}</span>,
    },
    {
      title: "Est Time",
      render: (record) => <span>{record.estimatedTime}</span>,
    },
    {
      title: "Price",
      render: (record) =>
        record.productSize === "multiSize" ? (
          <span
            className="gx-link"
            onClick={() => {
              dispatch({
                type: "togglePriceModal",
                payload: record.priceList,
              });
            }}
          >
            see prices
          </span>
        ) : (
          <span>{record.price}</span>
        ),
    },
    {
      title: "Date & Time",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")} </span>
      ),
    },
    {
      title: "Images",
      render: (record) => <ImagesList imagesList={record.images} />,
    },
    {
      title: "Status",
      render: (record) => <span class="status">{record.status}</span>,
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
    },
  ];

  loginUser === "superAdmin" ? (column = adminColumn) : (column = vendorColumn);

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetProductState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetProductState",
    });
  }

  const editRecord = (record) => {
    dispatch({
      type: "EditProduct_Modal",
      payload: record,
    });
  };

  const updateProductStatus = (record, status) => {
    record.status = status;
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProductStatus",
        record,
        "",
        "updatingStatus"
      )
    );
  };

  const renderActions = (record) => {
    if (record.status === "pending" && loginUser === "superAdmin") {
      return (
        <div className="gx-d-flex gx-flex-column">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateProductStatus(record, "approved")}
          >
            Approve
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateProductStatus(record, "reject")}
          >
            Reject
          </span>
        </div>
      );
    } else if (record.status === "approved" && loginUser === "superAdmin") {
      return (
        <div className="gx-d-flex gx-flex-column">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateProductStatus(record, "reject")}
          >
            Reject
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateProductStatus(record, "pending")}
          >
            Pending
          </span>
        </div>
      );
    } else if (record.status === "reject" && loginUser === "superAdmin") {
      return (
        <div className="gx-d-flex gx-flex-column">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateProductStatus(record, "approved")}
          >
            Approve
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => updateProductStatus(record, "pending")}
          >
            Pending
          </span>
        </div>
      );
    } else if (loginUser === "vendor") {
      return (
        <div className="gx-d-flex gx-flex-column">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => editRecord(record)}
          >
            Edit
          </span>
          <DeleteConfirm
            type="deleteProduct"
            method="DELETE"
            selectedItem={record}
            buttonName="Yes"
            title="Are you sure you want to delete this product?"
            content=""
            Icon={<span style={{ fontSize: "14px" }}>Delete</span>}
          />
        </div>
      );
    }
  };

  const { Product } = teamProps;

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        {query === "pending" ? (
          <Title title="Products" />
        ) : (
          <Title title="Products" />
        )}

        <div className="gx-d-flex">
          <div className="filters products-filter">
            {loginUser === "superAdmin" && (
              <CallFilters
                isShowingAll={true}
                apiName="productList"
                searchKey="createdId"
                options={addressList}
                placeholder="Filter by vendor address and vendor name"
                ItemValue="_id"
                label="name"
                className="product-filter"
              />
            )}

            <RangePicker
              onChange={onChange}
              className="gx-mr-3"
              style={{ width: "220px" }}
            />

            <CallFilters
              isShowingAll={true}
              apiName="productList"
              searchKey="categoryId"
              options={Category}
              placeholder="Filter by category"
              ItemValue="_id"
              label="name"
            />

            {loginUser === "superAdmin" && (
              <Input.Search
                showSearch
                placeholder="Product Name or ID"
                filterOption={false}
                onChange={(e) =>
                  dispatch(
                    DataGetAction(
                      "productList",
                      { query: "search", name: e.target.value },
                      ""
                    )
                  )
                }
                className="search-input"
              />
            )}
          </div>
          {loginUser !== "vendor" ? (
            ""
          ) : (
            <div
              className="gx-d-flex gx-align-items-center"
              style={{ marginLeft: "12px", flexWrap: "wrap", gap: "10px" }}
            >
              <BulkUploadModel />
              <AddProductForm initialRecord={initialValue} />
            </div>
          )}
        </div>
      </div>
      <ProductList data={Product} columns={column} />
      <SimpleModal
        title={
          <span style={{ color: "#041649" }} className="gx-font-sans-bold">
            Images
          </span>
        }
        modalFor="Images_Modal"
        visible={imagesModelVisible}
      >
        {imagesList && imagesList.length > 0 ? (
          <Carousel>
            {imagesList.map((item) => {
              let image = item.url ? item.url : item;
              return (
                <img
                  src={image}
                  width="100%"
                  height="300px"
                  style={{ objectFit: "cover", borderRadius: "8px" }}
                  alt=""
                />
              );
            })}
          </Carousel>
        ) : (
          "No attachments were found"
        )}
      </SimpleModal>

      <SimpleModal
        title={
          <span style={{ color: "#041649" }} className="gx-font-sans-bold">
            Prices
          </span>
        }
        modalFor="togglePriceModal"
        visible={priceModelVisible}
      >
        <div
          className="gx-p-3"
          style={{
            backgroundColor: "#F6F6F633",
            border: "1px solid #D2D2D233",
            borderRadius: "8px",
          }}
        >
          <p>
            <span className="gx-text-primary">small : </span>{" "}
            <span>{priceList && priceList.smallSizePrice}</span>
          </p>
          <p>
            <span className="gx-text-primary">medium : </span>{" "}
            <span>{priceList && priceList.mediumSizePrice}</span>
          </p>
          <p>
            <span className="gx-text-primary">large : </span>{" "}
            <span>{priceList && priceList.largeSizePrice}</span>
          </p>
        </div>
      </SimpleModal>
      {ShowModalEdit && initialValues && <EditProductForm />}
    </Layout>
  );
};

export default Index;
