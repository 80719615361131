const INIT_STATE = {
  Product: [],
  addingProduct: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
  SubCategoryList: [],
  imagesModelVisible: false,
  imagesList: [],
  addressList: [],
  priceModelVisible: false,
  priceList: null,
  CardData: null,
  ShowModalEdit: false,
  Bulk_Upload_Modal: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "updateProductStatus_SUCCESS": {
      const rec = getProductArray(state.Product, action.payload.Record);
      return { ...state, Product: [...rec] };
    }
    case "Show_Bulk_Modal": {
      return {
        ...state,
        Bulk_Upload_Modal: true,
      };
    }
    case "getVendorCards_SUCCESS": {
      return { ...state, CardData: action.payload.Record };
    }
    case "getSubCategory_SUCCESS": {
      return { ...state, SubCategoryList: action.payload.Record };
    }
    case "deleteProduct_SUCCESS": {
      const productArray = removeDeletedProductFromState(
        state.Product,
        action.payload.Record
      );
      return {
        ...state,
        Product: [...productArray],
        addingProduct: false,
        success: true,
        textMessage: "product deleted successfully",
      };
    }

    case "editProduct_SUCCESS": {
      const productArr = getProductArray(state.Product, action.payload.Record);
      return {
        ...state,
        initialValues: null,
        Product: [...productArr],
        addingProduct: false,
        success: true,
        textMessage: "product updated successfully",
      };
    }

    case "productList_SUCCESS": {
      return { ...state, Product: action.payload.Record };
    }

    case "addingProduct_START": {
      return { ...state, addingProduct: true, initialValues: null };
    }

    case "addNewProduct_SUCCESS": {
      if (JSON.parse(localStorage.getItem("userInfo")).role === "superAdmin") {
        return {
          ...state,
          Product: [action.payload.Record, ...state.Product],
        };
      } else {
        return { ...state };
      }
    }
    case "addProduct_SUCCESS": {
      return {
        ...state,
        Product: [action.payload.Record, ...state.Product],
        addingProduct: false,
        success: true,
        initialValues: null,
        textMessage: "product added successfully",
      };
    }
    case "addProduct_FAILURE": {
      return {
        ...state,
        addingProduct: false,
        success: false,
        error: true,
        textMessage: "some error occur while adding product. kindly try again",
      };
    }
    case "AddProduct_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }
    case "EditProduct_Modal": {
      return {
        ...state,
        ShowModalEdit: !state.ShowModalEdit,
        initialValues: !state.ShowModalEdit ? action.payload : null,
      };
    }
    case "ResetPassword_Modal": {
      return {
        ...state,
        ShowModalPassword: !state.ShowModalPassword,
        initialValues: action.payload,
      };
    }
    case "getAddress_SUCCESS": {
      return {
        ...state,
        addressList: action.payload.Record,
      };
    }

    case "resetProductState": {
      return {
        ...state,
        addingProduct: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
        ShowModalPassword: false,
      };
    }
    case "Images_Modal": {
      return {
        ...state,
        imagesModelVisible: !state.imagesModelVisible,
        imagesList: action.payload,
      };
    }
    case "togglePriceModal": {
      return {
        ...state,
        priceModelVisible: !state.priceModelVisible,
        priceList: action.payload ? action.payload : "",
      };
    }

    default: {
      return { ...state };
    }
  }

  function getProductArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedProductFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
