import React, { useEffect } from 'react'
import FormModal from 'components/Modal/FormModal'
import { Form, Input, Row, Col, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { DataGetAction } from 'redux/actions/http';




const AddSubCategory = ( ) => {

    const iuProps = useSelector(state => state.subCategoryR)
    const teamProps = useSelector(state => state.categoryR)
    const dispatch = useDispatch();

    const fetchCategoryList = () => {
        dispatch(DataGetAction('categoryList', { query: "all" }, 'fetchingCategoryList'))
    }
    useEffect(fetchCategoryList, [])

    const { ShowModal, addingSubCategory, initialValues } = iuProps
    const { Category } = teamProps;

    return (
        <div>
            <FormModal
                modalTitle={!initialValues || initialValues === "adding" ? <span className="gx-font-sans-bold">Add Sub Category</span> : <span className="gx-font-sans-bold">Update Sub Category</span>}
                basicButtonTitle="Add Sub Category"
                submitButtonText={!initialValues || initialValues === "adding" ? "Add" : "Update"}
                cancelButtonText="Cancel"
                apiMethod="POST"
                apiName="addSubCategory"
                editApiMethod="PUT"
                editApiName="editSubCategory"
                apiLoaderName="addingSubCategory"
                confirmLoading={addingSubCategory}
                ModalType="AddSubCategory_Modal"
                visible={ShowModal}
                initialValues={initialValues}
            >
                <Row className="gx-flex-row">
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                        
                            label="Category"
                            name="category"
                            rules={[{ required: true, message: 'Please choose your parent category!' }]}
                        >
                            <Select >
                                {Category && Category.map(Item => <Select.Option value={Item._id}>{Item.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                            label="Sub Category Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your Sub Category Name!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>



            </FormModal>
        </div>
    )
}

export default AddSubCategory
