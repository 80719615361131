import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartCard from "../../../../components/ChartCard";
import { DataGetAction } from "redux/actions/http";

const VendorChartComponent = () => {
  const dispatch = useDispatch();

  const userProps = useSelector((state) => state.productR);
  const { CardData } = userProps;

  const fetchCardData = () => {
    dispatch(DataGetAction("getVendorCards"));
    dispatch({ type: "resetUserInvitationState" });
  };
  useEffect(fetchCardData, []);

  const Data = [
    { name: "Page A", price: 200 },
    { name: "Page B", price: 1200 },
    { name: "Page C", price: 600 },
    { name: "Page D", price: 1600 },
    { name: "Page D", price: 1000 },
    { name: "Page H", price: 2260 },
    { name: "Page K", price: 800 },
  ];

  return (
    <Row>
      <Col xl={8} lg={8} md={12} sm={12} xs={24}>
        <ChartCard
          count={CardData && CardData.totalVendor}
          desc="Total Vendors"
          Data={Data}
          bgColor={"#F3ECF9"}
          borderColor={"#C39FDF"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={12} xs={24}>
        <ChartCard
          count={CardData && CardData.approvedProduct}
          desc="Total Active products"
          Data={Data}
          bgColor={"#E3F7D7"}
          borderColor={"#B8E986"}
        />
      </Col>
      <Col xl={8} lg={8} md={12} sm={12} xs={24}>
        <ChartCard
          count={CardData && CardData.totalSales}
          desc="Total Sale"
          Data={Data}
          bgColor={"#FCEAF4"}
          borderColor={"#F8C8E3"}
        />
      </Col>
    </Row>
  );
};

export default VendorChartComponent;
