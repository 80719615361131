import React from "react";
import { Route, Switch } from "react-router-dom";

import CustomerDashboard from "./Customer/dashboard";
import AdminDashboard from "./Admin";
import ViewVendorDashboard from "./Admin/Vendor/components/VendorDashboard";
import ViewFinancial from "./Admin/Users/components/ViewFinancialReport";
import RestPassword from "./Auth/restPassword";

import Users from "./Admin/Users";
import Category from "./Admin/Category";
import SubCategory from "./Admin/SubCategory";
import Vendor from "./Admin/Vendor";
import PendingVendor from "./Admin/Vendor/pendingVendor";
import Report from "./Admin/Report";
import Setting from "./Admin/Setting";
import ProductReport from "./Admin/Report/productReport";
import StaticUsers from "./Admin/Report/StaticUsers";
import adminOrder from "./Admin/Order";
import Product from "./Customer/Product";
import Order from "./Customer/Order";
import CustomerReport from "./Customer/Report";
import Offers from "./Customer/Offers";
import CustomerOrder from "./CustomerOrder";

const App = ({ match }) => (
  <div className={`gx-main-content-wrapper`}>
    <Switch>
      {/* <Route path={`${match.url}home`} component={Home}/> */}
      <Route
        exact
        path={`${match.url}admin/dashboard`}
        component={AdminDashboard}
      />
      <Route
        exact
        path={`${match.url}admin/vendorDashboard/:query`}
        component={ViewVendorDashboard}
      />

      <Route
        exact
        path={`${match.url}users/viewFinancialReport/:query`}
        component={ViewFinancial}
      />
      <Route exact path={`${match.url}admin/order`} component={adminOrder} />
      <Route exact path={`${match.url}admin/users`} component={Users} />
      <Route exact path={`${match.url}category`} component={Category} />
      <Route exact path={`${match.url}subCategory`} component={SubCategory} />
      <Route exact path={`${match.url}vendor`} component={Vendor} />
      <Route exact path={`${match.url}reportPage`} component={Report} />
      <Route
        exact
        path={`${match.url}productReport`}
        component={ProductReport}
      />
      <Route
        exact
        path={`${match.url}pendingVendor`}
        component={PendingVendor}
      />
      <Route exact path={`${match.url}staticUsers`} component={StaticUsers} />
      <Route exact path={`${match.url}setting`} component={Setting} />
      <Route path={`${match.url}resetPassword`} component={RestPassword} />
      <Route
        path={`${match.url}admin/resetPassword`}
        component={RestPassword}
      />
      {/* ============== Vendor Routes ============ */}
      <Route
        exact
        path={`${match.url}dashboard`}
        component={CustomerDashboard}
      />
      <Route exact path={`${match.url}order/:query`} component={Order} />
      <Route exact path={`${match.url}product/:query`} component={Product} />
      <Route exact path={`${match.url}report`} component={CustomerReport} />
      <Route exact path={`${match.url}offers`} component={Offers} />
      {/* ============== Customer Dashboard ============ */}
      <Route
        exact
        path={`${match.url}customerOrder/all`}
        component={CustomerOrder}
      />
    </Switch>
  </div>
);

export default App;
