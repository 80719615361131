import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import SubCategoryList from "components/Table/BasicTable";
import TableAction from "components/Table/Actions";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import AddSubCategoryForm from "./components/AddSubCategoryForm";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import { message, DatePicker, Input } from "antd";
import CallFilters from "components/Filters/CallFilters";
import moment from "moment";

const { RangePicker } = DatePicker;
const Index = () => {
  const dispatch = useDispatch();
  const teamProps = useSelector((state) => state.subCategoryR);
  const categoryProps = useSelector((state) => state.categoryR);

  const { success, error, textMessage, initialValues } = teamProps;
  const { Category } = categoryProps;
  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction(
        "subCategoryList",
        { query: "all" },
        "fetchingSubCategoryList"
      )
    );
    dispatch(
      DataGetAction("categoryList", { query: "all" }, "fetchingCategoryList")
    );
  };
  useEffect(fetchTeamUsers, []);

  const onChange = (dates, dateStrings) => {
    if (dates.length) {
      dispatch(
        DataGetAction(
          "subCategoryList",
          {
            query: "search",
            key: "date",
            startDate: dates[0],
            endDate: dates[1],
          },
          "gettingInspectionHistory"
        )
      );
    }
  };
  const columns = [
    {
      title: "Name",
      render: (record) => <span>{record.name}</span>,
    },
    {
      title: "Parent Category",
      render: (record) => <span>{record.categoryName || ""}</span>,
    },
    {
      title: "Added by",
      render: (record) => <span>{record.addedBy || ""}</span>,
    },
    {
      title: "Date & Time",
      render: (record) => (
        <span>{moment(record.createdAt).format("Do MMM YYYY, h:mm a")}</span>
      ),
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      width: "90px",
    },
  ];

  if (success) {
    message.success(textMessage);
    dispatch({
      type: "resetSubCategoryState",
    });
  }

  if (error) {
    message.error(textMessage);
    dispatch({
      type: "resetSubCategoryState",
    });
  }

  const editRecord = (record) => {
    dispatch({
      type: "AddSubCategory_Modal",
      payload: record,
    });
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <span style={{ cursor: "pointer" }} onClick={() => editRecord(record)}>
          Edit
        </span>
        <DeleteConfirm
          type="deleteSubCategory"
          method="DELETE"
          selectedItem={record}
          buttonName="Yes"
          title="Are you sure you want to delete this sub category?"
          content=""
          Icon={<span style={{ fontSize: "14px" }}>Delete</span>}
        />
      </div>
    );
  };

  const { SubCategory } = teamProps;

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between">
        <Title title="Sub Category" />
        <div className="gx-d-flex">
          <div className="filters" style={{ marginRight: "12px" }}>
            <RangePicker onChange={onChange} className="gx-mr-3" />

            <CallFilters
              isShowingAll={true}
              apiName="subCategoryList"
              searchKey="categoryId"
              options={Category}
              placeholder="Filter by category"
              ItemValue="_id"
              label="name"
            />

            <Input.Search
              showSearch
              placeholder="Search by name"
              filterOption={false}
              onChange={(e) =>
                dispatch(
                  DataGetAction("subCategoryList", { name: e.target.value }, "")
                )
              }
              style={{ width: "200px", marginLeft: "12px" }}
            />
          </div>
          <AddSubCategoryForm initialRecord={initialValues} />
        </div>
      </div>
      <SubCategoryList data={SubCategory} columns={columns} />
    </Layout>
  );
};

export default Index;
