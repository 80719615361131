/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "components/Modal/FormModal";
import { DataGetAction } from "redux/actions/http";
import { Form, Input, Row, Col, message } from "antd";
import AvatarUploader from "components/AvatarUploader";
const UpdateSettings = () => {
  const dispatch = useDispatch();
  const settingProps = useSelector((state) => state.SettingR);
  const {
    showModal,
    settingValues,
    editSuccess,
    editLoader,
    editError,
    textMessage,
  } = settingProps;
  const [initialDataSet, setInitialDataSet] = useState(null);

  const [form] = Form.useForm();
  let initialValues = null;
  initialValues = settingValues;
  if (editSuccess) {
    message.success(textMessage);
    dispatch(DataGetAction("getSetting", { query: "all" }, "gettingSetting"));
    dispatch({
      type: "updateSettingState",
    });
  }

  if (editError) {
    message.error(textMessage);
    dispatch({
      type: "updateSettingState",
    });
  }
  const initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  useEffect(() => {
    setInitialDataSet(initialValues);
  }, [initialValues]);

  return (
    <div>
      <span
        style={{ color: "grey" }}
        className="icon icon-edit gx-link"
        onClick={() => {
          dispatch({
            type: "editSettings_Modal",
            payload: null,
          });
        }}
      ></span>

      <FormModal
        modalTitle={"Update Settings"}
        basicButtonTitle="Reset Password"
        submitButtonText={"save"}
        cancelButtonText="Cancel"
        apiMethod="POST"
        apiName="addSetting"
        apiLoaderName="addingSetting"
        editApiMethod="PUT"
        editApiName="editSetting"
        confirmLoading={editLoader}
        ModalType="editSettings_Modal"
        visible={showModal}
        buttonStyleClass="gx-buttonStyleClass"
        isShowButton={false}
        initialValues={initialDataSet}
        form={form}
        width={640}
        extraFieldName="profileImageUrl"
        extraFieldValue={photoList.imageUrl}
      >
        {initialDataSet !== null && (
          <>
            {" "}
            <Row className="gx-d-flex gx-flex-row">
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter client name!",
                        },
                      ]}
                      initialValue={initialDataSet && initialDataSet.firstName}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter client name!",
                        },
                      ]}
                      initialValue={initialDataSet && initialDataSet.lastName}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter email!",
                        },
                      ]}
                      initialValue={initialDataSet && initialDataSet.email}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Form.Item name="photo">
                  <AvatarUploader
                    setPhotoState={setPhotoState}
                    photoList={photoList}
                  />
                </Form.Item>
                {/* <img src={'https://images.unsplash.com/photo-1536063211352-0b94219f6212?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max'} alt="" style={{ borderRadius: '50%', width: '300px', height: '300px', objectFit: "cover" }} /> */}
              </Col>
            </Row>
            <Row className="gx-d-flex gx-flex-row gx-px-3">
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input phone number!",
                    },
                  ]}
                  initialValue={initialDataSet && initialDataSet.phoneNumber}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </FormModal>
    </div>
  );
};

export default UpdateSettings;
