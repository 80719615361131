import React, { useEffect, useState } from "react";
import { Card, Col, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import SimpleLineChart from "./components/SimpleLineChart";
import { useParams } from "react-router-dom";
import ChartCard from "../../ChartCard";
const Index = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [selectUserId, setUserId] = useState(null);
  const userProps = useSelector((state) => state.usersR);
  const { MonthlySpending, userName, Users } = userProps;
  const fetchUserData = (value) => {
    let userId = params && params.query;
    if (value) {
      userId = value;
    }
    setUserId(userId);
    dispatch(DataGetAction("getUserName", { userId: userId }));
    dispatch(DataGetAction("getUserMonthlySpending", { userId: userId }));
    dispatch(DataGetAction("getUsersList", { userId: userId }));
  };
  useEffect(fetchUserData, []);

  const Data = [
    { name: "Page A", price: 200 },
    { name: "Page B", price: 1200 },
    { name: "Page C", price: 600 },
    { name: "Page D", price: 1600 },
    { name: "Page D", price: 1000 },
    { name: "Page H", price: 2260 },
    { name: "Page K", price: 800 },
  ];

  return (
    <div className="gx-h-100">
      <div className="gx-w-100 gx-mb-3 gx-d-flex gx-align-items-center gx-justify-content-between">
        <h4 className="gx-mb-0 gx-text-capitalize">
          {userName && userName.firstName + " " + userName.lastName}
        </h4>
        <Select
          value={selectUserId}
          onChange={fetchUserData}
          placeholder="Select Vendor"
          style={{ width: "200px" }}
        >
          {Users &&
            Users.map((items) => (
              <Select.Option value={items._id}>
                {items.firstName + " " + items.lastName}
              </Select.Option>
            ))}
        </Select>
      </div>
      <Row>
        <Col xl={8} lg={8} md={12} sm={12} xs={24}>
          <ChartCard
            count={(MonthlySpending && MonthlySpending.averageSending) || 0}
            desc="Average Spending"
            Data={Data}
            bgColor={"#FCEAF4"}
            borderColor={"#F8C8E3"}
          />
        </Col>
        <Col xl={8} lg={8} md={12} sm={12} xs={24}>
          <ChartCard
            count={(MonthlySpending && MonthlySpending.activeOrders) || 0}
            desc="No of Active Orders"
            Data={Data}
            bgColor={"#E3F7D7"}
            borderColor={"#B8E986"}
          />
        </Col>
        <Col xl={8} lg={8} md={12} sm={12} xs={24}>
          <ChartCard
            count={(MonthlySpending && MonthlySpending.completedOrders) || 0}
            desc="No of Completed Orders"
            Data={Data}
            bgColor={"#F3ECF9"}
            borderColor={"#C39FDF"}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">
                Yearly Spending
              </h4>
            }
          >
            <SimpleLineChart
              data={MonthlySpending && MonthlySpending.monthlySpending}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
