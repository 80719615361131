import React, { useEffect } from "react";
import DashboardData from "components/Dashboard/Customer";
import Layout from "components/LayoutForPortal";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
const Dashboard = () => {
  const dispatch = useDispatch();
  const fetchDashboardData = () => {
    dispatch(DataGetAction("getDashboardOverView", "", "fetchingData"));
    dispatch(DataGetAction("getTopPerFormingProducts", "", "fetchingData"));
  };
  useEffect(fetchDashboardData, []);
  const dashProps = useSelector((state) => state.dashboard);
  const { dashboardData, topProducts } = dashProps;
  return (
    <Layout>
      <DashboardData dashboardData={dashboardData} topProducts={topProducts} />
    </Layout>
  );
};

export default Dashboard;
