import React, { useState } from "react";
import UploadCSV from "../../../../components/uploadCSV";
import { Button, Modal } from "antd";
const BulkUploadModel = () => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [categoryValue, setCategoryValue] = useState(null);
  const [subCategoryValue, setSubCategoryValue] = useState(null);
  return (
    <>
      <Button className="gx-py-0" onClick={() => setOpen(true)} type="primary">
        Upload CSV
      </Button>
      <Modal onCancel={() => setOpen(false)} open={open} footer={null}>
        <UploadCSV
          setState={setState}
          state={state}
          setCategoryValue={setCategoryValue}
          categoryValue={categoryValue}
          setSubCategoryValue={setSubCategoryValue}
          subCategoryValue={subCategoryValue}
          type="productList"
          setOpen={setOpen}
          url="bulkProductUpload"
        />
      </Modal>
    </>
  );
};

export default BulkUploadModel;
