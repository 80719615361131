import React from "react";
import { Form, Input, Button, Spin, Checkbox, Row, Col } from "antd";
import Widget from "../../Widget";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/http";
import { setInitUrl } from "../../../redux/actions/Auth";
import { useHistory } from "react-router-dom";
// import Google from '../../../assets/google.svg'
// import linkedIn from '../../../assets/linkedin.svg'
import { MailOutlined, LockOutlined } from "@ant-design/icons";
// import _ from 'lodash';
import { Socket } from "../../../socket";
// const BackEndURL = process.env.REACT_APP_ROOT_URL
const SignIn = (props) => {
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const history = useHistory();
  const { loginFailMessage, loginFail, loginSuccess } = authProps;
  const onFinish = (values) => {
    dispatch(
      DataRequestAction("POST", "login", values, "", "loginStart", false)
    );
  };

  const redirectToSignUp = () => {
    history.push("/signup");
  };
  const redirectToForgotPage = () => {
    history.push("/forgotPassword");
  };

  if (loginSuccess) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    Socket.getInstance(userInfo._id);
    dispatch({
      type: "update_auth",
    });
    if (userInfo.role === "vendor") {
      dispatch(setInitUrl("/dashboard"));

      history.push("/dashboard");
    } else if (userInfo.role === "superAdmin") {
      dispatch(setInitUrl("/admin/dashboard"));
      history.push("/admin/dashboard");
    } else if (userInfo.role === "customer") {
      dispatch(setInitUrl("/customerOrder/all"));
      history.push("/customerOrder/all");
    } else {
      dispatch(setInitUrl("/"));
      history.push("/");
    }
  }

  return (
    <Widget styleName="gx-paddingAuth">
      <div className="SignIn gx-p-5">
        <div className="welcomeMessage gx-text-center gx-mb-4">
          <h2 className="gx-font-weight-bold gx-mb-0 gx-font-sans-regular gx-blue-font gx-font-35">
            Welcome Back!
          </h2>
          <p className="gx-mb-0 gx-gray-font gx-font-sans-regular">
            Login to your account
          </p>
          {loginFail && (
            <span
              style={{
                color: "red",
              }}
            >
              {loginFailMessage}
            </span>
          )}
        </div>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            className="gx-p-0 gx-m-0 gx-mb-3"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              placeholder="Email"
              prefix={
                <MailOutlined
                  style={{
                    marginRight: "5px",
                  }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            name="password"
            className="gx-p-0 gx-m-0 gx-mb-2"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              type="password"
              placeholder="Password"
              prefix={
                <LockOutlined
                  style={{
                    marginRight: "5px",
                  }}
                />
              }
            />
          </Form.Item>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-pl-5">
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <Form.Item className="gx-p-0 gx-m-0">
                <span
                  onClick={() => redirectToForgotPage()}
                  className="login-form-forgot gx-font-weight-light gx-float-right gx-pointer gx-link gx-font-sans-regular"
                  style={{ color: "#3FD4A2", fontSize: "15px" }}
                >
                  {" "}
                  Forgot password{" "}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="gx-p-0 gx-m-0">
            {!authProps.loginStart && (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center gx-font-sans-regular"
                style={{ lineHeight: "0px" }}
              >
                Log in
              </Button>
            )}
            {authProps.loginStart && (
              <Spin>
                <Button
                  type="primary"
                  htmlType="button"
                  className="login-form-button gx-mb-0 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center gx-font-sans-regular"
                  style={{ lineHeight: "0px" }}
                >
                  Log in
                </Button>
              </Spin>
            )}
          </Form.Item>
          <p
            className="gx-text-center gx-font-sans-regular"
            onClick={() => redirectToSignUp()}
          >
            Need an account?{" "}
            <span
              className="gx-pointer gx-link gx-font-sans-regular"
              style={{
                color: "#3FD4A2",
              }}
            >
              Create account
            </span>
          </p>
        </Form>
      </div>
    </Widget>
  );
};

export default SignIn;
