import React from "react";

import Layout from "components/LayoutForPortal";
import UserViewData from "../../../../components/Dashboard/UserView";

const Index = () => {
  return (
    <Layout>
      <UserViewData />
    </Layout>
  );
};

export default Index;
