const INITIAL_STATE = {
  vendor_order_badge: true,
  vendor_badge: false,
  product_badge: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "productBadge_SUCCESS": {
      return { ...state, product_badge: !state.product_badge };
    }
    case "vendorBadge_SUCCESS": {
      return { ...state, vendor_badge: !state.vendor_badge };
    }
    case "orderBadge_SUCCESS": {
      return { ...state, vendor_order_badge: !state.vendor_order_badge };
    }

    default:
      return state;
  }
};
