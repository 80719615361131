import React, { useEffect } from "react";
import { Button, Modal, Form } from "antd";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "redux/actions/http";

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  confirmLoading,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  children,
  initialValues,
  initialRecord,
  width,
  shouldClearForm,
  className,
  address,
}) => {
  const [form] = Form.useForm();

  const setData = () => {
    if (address) {
      form.setFieldsValue({ address: address });
    }
    if (visible && !initialValues && !address) {
      form.setFieldsValue({ address: undefined });
    }
  };

  useEffect(setData, [address, visible]);

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={modalTitle}
      width={width}
      form={form}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      okText={submitButtonText}
      cancelText={cancelButtonText}
      className={className}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        initialValues={initialValues}
        form={form}
        layout="vertical"
        name="form_in_modal"
      >
        {children}
      </Form>
    </Modal>
  );
};

const CollectionsPage = ({
  children,
  modalTitle,
  basicButtonTitle,
  submitButtonText,
  cancelButtonText,
  confirmLoading,
  apiMethod,
  apiName,
  visible,
  ModalType,
  editApiMethod,
  editApiName,
  initialRecord,
  width,
  initialValues,
  shouldClearForm,
  className,
  form,
  apiLoaderName,
  buttonStyleClass,
  isShowButton = true,
  extraFieldName,
  extraFieldValue,
  address,
}) => {
  const dispatch = useDispatch();
  const onCreate = (values) => {
    if (values.email) {
      values.email = values.email.toLowerCase();
    }

    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue;
    }

    if (initialValues) {
      values._id = initialValues._id;
      values.inspectorId = initialValues.inspectorId;
      dispatch(
        DataRequestAction(
          editApiMethod,
          editApiName,
          values,
          "",
          apiLoaderName,
          false
        )
      );
    } else {
      console.log("form submit success", values);
      dispatch(
        DataRequestAction(apiMethod, apiName, values, "", apiLoaderName, false)
      );
    }
  };

  return (
    <div>
      {isShowButton && (
        <Button
          type="primary"
          className={`gx-pt-0 ${buttonStyleClass}`}
          onClick={() => {
            dispatch({
              type: ModalType,
              payload: null,
            });
          }}
        >
          {basicButtonTitle}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        apiName={apiName}
        ModalType={ModalType}
        initialValues={initialValues}
        initialRecord={initialRecord}
        confirmLoading={confirmLoading}
        apiLoaderName={apiLoaderName}
        shouldClearForm={shouldClearForm}
        visible={visible}
        width={width}
        className={className}
        form={form}
        onCreate={onCreate}
        address={address}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            clear: true,
          });
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  );
};

export default CollectionsPage;
