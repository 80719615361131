const INIT_STATE = {
  drawerVisibility: false,
  topPerformingVendors: [],
  dashboardData: null,
  ActiveUserStatistic: [],
  SpendingAnalysis: [],
  monthlyAnalytic: [],
  storeDashboardCards: null,
  TopPerFormingProducts: null,
  RevenueData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "Admin_Drawer": {
      return { ...state, drawerVisibility: !state.drawerVisibility };
    }
    case "getAdminDashboardOverView_SUCCESS": {
      return { ...state, dashboardData: action.payload.Record };
    }
    case "newAppUserCreatedSocket_SUCCESS": {
      let Record = state.dashboardData;
      if (action.payload) {
        Record.appUsers = action.payload.cardData.appUsers;
      }

      return {
        ...state,
        dashboardData: Record,
        userStatistic: action.payload.graphData,
        ActiveUserStatistic: action.payload.dailyActivity,
      };
    }
    // case "newAppUserCreatedSocket_SUCCESS": {
    //   let Record = state.dashboardData;
    //   if (action.payload) {
    //     Record.totalSale = action.payload.totalSale;
    //     Record.totalTodayRevenue = action.payload.totalTodayRevenue;
    //   }

    //   return {
    //     ...state,
    //     dashboardData: Record,
    //   };
    // }
    case "getStoreDashboardCards_SUCCESS": {
      return {
        ...state,
        storeDashboardCards: action.payload.Record,
      };
    }
    case "getTotalStoreRevenue_SUCCESS": {
      return {
        ...state,
        RevenueData: action.payload.Record,
      };
    }
    case "getMonthlyAnalysisForStoreSales_SUCCESS": {
      return {
        ...state,
        MonthlyAnalysisForStoreSales: action.payload.Record,
      };
    }
    case "getStoreTopPerFormingProducts_SUCCESS": {
      return {
        ...state,
        TopPerFormingProducts: action.payload.Record,
      };
    }
    case "getTopPerFormingVendor_SUCCESS": {
      action.payload.Record.map((item) => {
        if (!item.totalSale) {
          item.totalSale = 0;
        }
        return item;
      });
      return { ...state, topPerformingVendors: action.payload.Record };
    }
    case "getMonthlyAnalytic_SUCCESS": {
      return {
        ...state,
        monthlyAnalytic: action.payload.Record,
      };
    }

    case "getStatisticsForNoOfSignup_SUCCESS": {
      return {
        ...state,
        userStatistic: action.payload.Record,
      };
    }
    case "getUserDailyActivityGraph_SUCCESS": {
      return {
        ...state,
        ActiveUserStatistic: action.payload.Record,
      };
    }
    case "getSpendingAnalysisForAllCustomers_SUCCESS": {
      return {
        ...state,
        SpendingAnalysis: action.payload.Record,
      };
    }
    default:
      return state;
  }
};
