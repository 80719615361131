import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import { logoutFn } from 'redux/actions/Common'
import alertMessage from '../../constants';
import { Menu, Dropdown, message } from 'antd';
import { setInitUrl } from 'redux/actions/Auth'

import {  DownOutlined } from '@ant-design/icons';

const UserInfo = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const authProps = useSelector(state => state.auth);


  const logout = () => {

    message.info(alertMessage.Logout)
    dispatch(logoutFn());
  }

  if (authProps.logoutSuccess) {
    dispatch({
      type:'update_auth'
    });
    history.push('/')
  }

  const resetPassword = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.role === "superAdmin") {
      dispatch(setInitUrl('/admin/resetPassword'));
      history.push('/admin/resetPassword');
    }
    if (userInfo.role === "vendor") {
      dispatch(setInitUrl('/resetPassword'));
      history.push('/resetPassword');
    }
   
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => resetPassword()}>
        <span>Reset Password</span>
      </Menu.Item>
      <Menu.Item onClick={() => logout()}>
        <span className="gx-pointer" >Logout</span>
      </Menu.Item>
    </Menu>
  );


  const getName = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let name = "";
    if(userInfo.clientName){
      name = userInfo.clientName
    }else {
      name = userInfo.firstName + " "+ userInfo.lastName
    }
    return name
  }

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link gx-pointer " style={{
        textTransform: 'capitalize'
      }} onClick={e => e.preventDefault()}>
        {getName()} <DownOutlined />
      </span>
    </Dropdown>
  )

}

export default UserInfo;
