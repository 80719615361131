const INIT_STATE = {
  Currency_Setting_Modal: false,
  currencyValues: null,
  currencyMessage: "",
  currencyError: false,
  currencySuccess: false,
  currencyButtonSpin: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "addCurrency_SUCCESS": {
      return {
        ...state,
        Currency_Setting_Modal: false,
        currencyButtonSpin: false,
        currencySuccess: true,
        currencyMessage: action.payload.message,
      };
    }
    case "editCurrency_SUCCESS": {
      return {
        ...state,
        currencyMessage: action.payload.message,
        currencySuccess: true,
        Currency_Setting_Modal: false,
        currencyButtonSpin: false,
      };
    }
    case "addCurrency_FAILURE": {
      return {
        ...state,
        currencyButtonSpin: false,
        currencyError: true,
        currencyMessage: action.payload,
      };
    }
    case "editCurrency_FAILURE": {
      return {
        ...state,
        currencyMessage: action.payload,
        currencyError: true,
        currencyButtonSpin: false,
      };
    }
    case "currencyButtonSpin_START": {
      return {
        ...state,
        currencyButtonSpin: true,
      };
    }
    case "getCurrency_SUCCESS": {
      return {
        ...state,
        currencyValues: action.payload.Record,
      };
    }
    case "Currency_Setting_Modal": {
      return {
        ...state,
        Currency_Setting_Modal: !state.Currency_Setting_Modal,
        currencyValues: state.currencyValues,
      };
    }
    case "RESET_CURRENCY": {
      return {
        currencyMessage: "",
        currencyError: false,
        currencySuccess: false,
        currencyButtonSpin: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};
