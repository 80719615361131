import React, { useEffect } from "react";
import FormModal from "components/Modal/FormModal";
import { Form, Input, Row, Col, Select, Space, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "redux/actions/http";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
const InviteUser = ({ initialRecord }) => {
  const [form] = useForm();
  const iuProps = useSelector((state) => state.orderR);
  const { currencyValues } = useSelector((state) => state.currencyR);
  const dispatch = useDispatch();

  const { ShowModal, addingOrder, initialValues, Products, Store } = iuProps;

  const fetchStores = () => {
    dispatch(
      DataGetAction("getStoreList", { query: "all" }, "fetchingStoreList")
    );
  };

  useEffect(fetchStores, []);

  const fetchProductListByStore = (storeId) => {
    dispatch(
      DataGetAction(
        "getProductList",
        { query: "all", storeId: storeId },
        "fetchingProductList"
      )
    );
    dispatch(
      DataGetAction(
        "getCustomerList",
        { query: "all", storeId: storeId },
        "fetchingCustomerList"
      )
    );
    dispatch(DataGetAction("getCurrency", "", ""));
  };
  const handleChange = (value, priceFieldName) => {};
  return (
    <div>
      <FormModal
        modalTitle={
          !initialValues || initialValues === "adding"
            ? "Add Order"
            : "Update Order"
        }
        basicButtonTitle="Add Order"
        submitButtonText={
          !initialValues || initialValues === "adding" ? "Add" : "Update"
        }
        cancelButtonText="Cancel"
        apiMethod="POST"
        apiName="addOrder"
        editApiMethod="PUT"
        editApiName="editOrder"
        apiLoaderName="addingOrder"
        confirmLoading={addingOrder}
        ModalType="AddOrder_Modal"
        visible={ShowModal}
        extraFieldName="productArray"
        extraFieldValue={Products}
        initialValues={initialValues}
        initialRecord={initialRecord}
        form={form}
      >
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              label="Store"
              name="storeId"
              rules={[
                { required: true, message: "Please input your Qty Name!" },
              ]}
            >
              <Select
                onChange={(data) => {
                  fetchProductListByStore(data);
                }}
              >
                {Store &&
                  Store.map((Item) => (
                    <Select.Option value={Item._id}>{Item.name}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>

          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.List name="orderItems">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          label="Product"
                          name={[field.name, "productId"]}
                          fieldKey={[field.fieldKey, "productId"]}
                        >
                          <Select
                            style={{
                              width: 150,
                            }}
                          >
                            {Products &&
                              Products.map((Item) => (
                                <Select.Option value={Item._id}>
                                  {Item.productName}(
                                  {(currencyValues &&
                                    currencyValues.currencySymbol) ||
                                    ""}
                                  {Item.price})
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Qty"
                          name={[field.name, "orderQty"]}
                          fieldKey={[field.fieldKey, "orderQty"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input your Qty Name!",
                            },
                          ]}
                        >
                          <Input
                            onKeyUp={(data) =>
                              handleChange(data.target.value, [
                                field.name,
                                "orderPrice",
                              ])
                            }
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        className="gx-pt-0"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Items
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Row>
      </FormModal>
    </div>
  );
};

export default InviteUser;
