import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Title from "components/Title";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { renderStatus } from "../../../components/utils/commonUseFunction";
import { DataGetAction } from "redux/actions/http";
import UserList from "components/Table/BasicTable";
import { Input } from "antd";
const Index = () => {
  const dispatch = useDispatch();
  const reportProps = useSelector((state) => state.reportR);
  const { StaticUsers } = reportProps;
  const fetchTeamUsers = () => {
    dispatch(
      DataGetAction(
        "getUserWithNoTransaction",
        { query: "all" },
        "fetchingStaticUsersList"
      )
    );
  };

  useEffect(fetchTeamUsers, []);
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.userId}</span>,
    },
    {
      title: "Name",
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.userName || "---"}
        </span>
      ),
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
    },
    {
      title: "Status",
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status) || "---"}
        </span>
      ),
      key: "status",
    },
    {
      title: "Mobile Type",
      render: (record) => <span>{record.mobileType || "---"}</span>,
    },
    {
      title: "Signup Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("DD MMM YYYY, h:mm a")}</span>
      ),
    },
  ];

  return (
    <Layout>
      <div className="gx-d-block gx-flex-row gx-justify-content-between gx-my-2">
        <Title title="Static Users" />
        <div className="filters" style={{ marginRight: "12px" }}>
          <Input.Search
            showSearch
            placeholder="Search by Id/name/email"
            filterOption={false}
            onChange={(e) =>
              dispatch(
                DataGetAction(
                  "getUserWithNoTransaction",
                  { name: e.target.value },
                  ""
                )
              )
            }
            style={{ width: "250px", marginLeft: "12px" }}
          />
        </div>
      </div>

      <UserList data={StaticUsers} columns={columns} />
    </Layout>
  );
};

export default Index;
