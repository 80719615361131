import React, { useEffect } from 'react'
import Layout from 'components/LayoutForPortal'
import Title from 'components/Title'
import ProductList from 'components/Table/BasicTable'
import TableAction from 'components/Table/Actions'
import DeleteConfirm from 'components/Modal/DeleteConfirm'
import AddOfferForm from './components/AddOfferForm'
import { useDispatch, useSelector } from 'react-redux'
import { DataGetAction, DataRequestAction } from 'redux/actions/http';
import { message, DatePicker, Input} from 'antd'
import ProductListModal from './components/ProductListModal'
import SimpleModal from 'components/Modal/SimpleModal'
const { RangePicker } = DatePicker;
const Index = () => {


    const dispatch = useDispatch();
    const offersProps = useSelector(state => state.offerR)
    const { success, error, textMessage, productsModalList, productsModelVisible } = offersProps
    const fetchOffersList = () => {
        dispatch(DataGetAction('offerList', { query: 'all' }, 'fetchingOffers'));
    }
    useEffect(fetchOffersList, [])
    const onChange = (dates, dateStrings) => {
        if (dates && dates.length) {
            dispatch(DataGetAction('offerList', { query: "search", key: "date", startDate: dates[0], endDate: dates[1] }, 'gettingOfferList'));
        }
    }
    let column = [
        {
            title: 'Offer No',
            render: record => <span>{record.offerNo}</span>,
        },
        {
            title: 'Offer Name',
            render: record => <span>{record.offerName}</span>,
        },
        {
            title: 'Offer percentage',
            render: record => <span>{record.pricePercentage}%</span>,
        },
        {
            title: 'Products',
            render: record => <ProductListModal productList={record.productInfo} />,
        },
        {
            title: 'Status',
            render: record => <span class="status">{record.status}</span>,
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
        },
    ];
    if (success) {
        message.success(textMessage)
        dispatch({
            type: "resetOfferState"
        })
    }
    if (error) {
        message.error(textMessage)
        dispatch({
            type: "resetOfferState"
        })
    }
    const editRecord = (record) => {
        dispatch({
            type: "AddOffer_Modal",
            payload: record
        })
    }
    const updateOfferStatus = (record, status) => {
        record.status = status;
        dispatch(DataRequestAction('PUT', 'updateOfferStatus', record, '', 'updatingOfferStatus'));
    }
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            {record.status !== 'disable' && <span style={{ cursor: 'pointer' }} onClick={() => updateOfferStatus(record, 'disable')}>Disable</span>}
            {record.status !== 'enable' && <span style={{ cursor: 'pointer' }} onClick={() => updateOfferStatus(record, 'enable')}>Enable</span>}
            <span style={{ cursor: 'pointer' }} onClick={() => editRecord(record)}>Edit</span>
            <DeleteConfirm
                type="deleteOffer"
                method="DELETE"
                selectedItem={record}
                buttonName="Yes"
                title="Are you sure you want to delete this product?"
                content=""
                Icon={<span style={{ fontSize: '14px' }}>Delete</span>}
            />
        </div>
    }
    const { offers } = offersProps;
    return (
        <Layout>
            <div className="gx-d-block gx-flex-row gx-justify-content-between">
                <Title title="Offers" />
                <div className="gx-d-flex">
                    <div className="filters products-filter" >
                        <RangePicker onChange={onChange} className="gx-mr-3" style={{ width: '220px' }} />
                        {/* <CallFilters isShowingAll={true} apiName="offerList" searchKey="status" options={[
                            { status: "enable", label: "Enable" },
                            { status: "disable", "label": "Disable" },
                        ]} placeholder="Search by status" ItemValue="status" label="label" /> */}
                        <Input.Search
                            showSearch
                            placeholder="Offer Name or No"
                            filterOption={false}
                            onChange={(e) => dispatch(DataGetAction('offerList', { query: "search", name: e.target.value }, ''))}
                            style={{ width: '200px', marginLeft: '12px', marginRight: '12px' }}
                        />
                    </div>
                    <div style={{ marginLeft: '12px' }}><AddOfferForm /></div>
                </div>
            </div>
            <ProductList data={offers} columns={column} />
            <SimpleModal title={
                <span style={{ color: '#041649' }} className="gx-font-sans-bold">Images</span>
            }
                modalFor="Products_Modal"
                visible={productsModelVisible}>
                <div className="gx-p-3" style={{ backgroundColor: '#F6F6F633', border: '1px solid #D2D2D233', borderRadius: '8px' }}>
                    {
                        productsModalList && productsModalList.map(item => {
                            return <span className="gx-d-block gx-mb-1">{item.productName}</span>
                        })
                    }
                </div>
            </SimpleModal>
        </Layout>
    )
}

export default Index
