const INIT_STATE = {
  Users: [],
  invitingUser: false,
  ShowModal: false,
  success: false,
  error: false,
  textMessage: "",
  initialValues: null,
  ShowModalPassword: false,
  userDetail: null,
  userDetailFetchLoader: false,
  userDetailFetchSuccess: false,
  userDetailFetchError: false,
  stores: [],
  zoomLevel: 14,
  deleteVendorSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "setZoomLevel": {
      return {
        ...state,
        zoomLevel: action.payload,
      };
    }

    case "fetchingDetail_START": {
      return { ...state, userDetailFetchLoader: true };
    }
    case "getUserDetail_SUCCESS": {
      return {
        ...state,
        userDetail: action.payload.Record[0],
        userDetailFetchLoader: false,
        userDetailFetchSuccess: true,
      };
    }
    case "getUserDetail_FAILURE": {
      return {
        ...state,
        userDetailFetchLoader: false,
        userDetailFetchSuccess: false,
        userDetailFetchError: true,
        textMessage: action.payload,
      };
    }
    case "updateStatus_SUCCESS": {
      const userStatusArr = getUserArrayByChangeStatus(
        state.Users,
        action.reqData
      );
      return {
        ...state,
        Users: [...userStatusArr],
        invitingUser: false,
        success: true,
        textMessage: "user updated successfully",
      };
    }
    case "changeVendorPassword_SUCCESS": {
      return {
        ...state,
        invitingUser: false,
        success: true,
        textMessage: "password set successfully",
        ShowModalPassword: false,
      };
    }

    case "changeVendorPassword_FAILURE": {
      return {
        ...state,
        invitingUser: false,
        success: false,
        error: true,
        textMessage:
          "some error occur while changing user password. kindly try again",
      };
    }

    case "deleteVendor_SUCCESS": {
      const userArray = removeDeletedUserFromState(
        state.Users,
        action.payload.Record
      );
      console.log("=====CASE DELETE =====");
      return {
        ...state,
        deleteVendorSuccess: true,
        Users: [...userArray],
        invitingUser: false,
        success: true,
        textMessage: "user deleted successfully",
      };
    }

    case "editVendor_SUCCESS": {
      const userArr = getUserArray(state.Users, action.payload.Record);
      return {
        ...state,
        Users: [...userArr],
        initialValues: null,
        invitingUser: false,
        success: true,
        textMessage: "user updated successfully",
      };
    }
    case "resentInvitationEmail_SUCCESS": {
      return {
        ...state,
        success: true,
        textMessage: "user invited successfully",
      };
    }
    case "vendorList_SUCCESS": {
      return {
        ...state,
        Users: action.payload.Record,
        stores: action.payload.Record,
      };
    }

    case "addingVendor_START": {
      return {
        ...state,
        invitingUser: true,
        initialValues: null,
        initialRecord: null,
      };
    }
    case "addVendor_SUCCESS": {
      return {
        ...state,
        initialValues: null,
        deleteVendorSuccess: true,
        Users: [action.payload.Record, ...state.Users],
        invitingUser: false,
        success: true,
        textMessage: "user invited successfully",
      };
    }
    case "addVendor_FAILURE": {
      return {
        ...state,
        invitingUser: false,
        success: false,
        error: true,
        textMessage: action.payload,
      };
    }
    case "InviteUser_Modal": {
      return {
        ...state,
        ShowModal: !state.ShowModal,
        initialValues: !state.ShowModal ? action.payload : null,
      };
    }
    case "ResetPassword_Modal": {
      return {
        ...state,
        ShowModalPassword: !state.ShowModalPassword,
        initialValues: action.payload,
      };
    }

    case "resetUserInvitationState": {
      return {
        ...state,
        invitingUser: false,
        ShowModal: false,
        success: false,
        error: false,
        textMessage: "",
        initialValues: null,
        ShowModalPassword: false,
        deleteVendorSuccess: false,
      };
    }

    default: {
      return { ...state };
    }
  }

  function getUserArray(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData._id) {
        newArray.push(newData);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
  function getUserArrayByChangeStatus(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id === newData.userId) {
        Item.status = newData.status;
        newArray.push(Item);
      } else {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }

  function removeDeletedUserFromState(oldData, newData) {
    let newArray = [];
    oldData.map((Item) => {
      if (Item._id !== newData._id) {
        newArray.push(Item);
      }
      return Item;
    });
    return newArray;
  }
};
