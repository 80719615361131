import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const TinyBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        data={data}
        margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="8 8" />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalSignUp" fill="#041649" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TinyBarChart;
